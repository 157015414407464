import React, { useEffect } from 'react';
import ReadMoreReadLess from '../../components/readMoreReadLess';
import { data } from '../../utils/demo';
// import Message from '../landing page/message/Message';
import Updated from '../landing page/updated/Updated';
import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import SoftwarePricing from '../../components/SoftwarePricing';


export default function Pricing() {

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <> 
     <div className='pricing mx-20'>

      <SoftwarePricing/>

     <div className=''>
     <h1 className='text-4xl mt-[65px] lg:mt-[130px] text-center font-medium'><b>Customer Appreciation!</b></h1>
     <div className='flex flex-col lg:flex-row mt-[45px] lg:mt-[90px] mx-12 lg:mx-44 space-y-4 lg:space-y-0'>

      <div className='flex flex-col lg:flex-row lg:w-4/12'>
      <img className="h-20 w-20 mx-auto" src={data.stagoenix} alt="Sunset in the mountains"/>
      <div className="lg:w-10/12">
        <div className="lg:px-6 py-4 text-center lg:text-left">
          <div className="text-2xl mb-2 mx-auto">Stagoenix</div>
          <ReadMoreReadLess limit={200}>
        ''We had been trying to tap into the WhatsApp Official API solution for a while now. However, the technicalities of setting up the product from scratch was a huge challenge for us. That's when we came across WappSolutya's whitelabel solution. With a minimum initial investment that we made, the returns are insane. Plus, the product is so well made and is no doubt a viable business solution for years to come.''
          </ReadMoreReadLess>
        </div>
      </div>
      </div>
     
      <div className='flex flex-col lg:flex-row lg:w-4/12 lg:text-left'>
      <img className="h-20 w-20 mx-auto" src={data.digital} alt="Sunset in the mountains"/>
      <div className="lg:w-10/12">
        <div className="lg:px-6 py-4 text-center lg:text-left">
          <div className="text-2xl mb-2">The Digital Walrus</div>
          <ReadMoreReadLess limit={200}>
        ''We had been trying to tap into the WhatsApp Official API solution for a while now. However, the technicalities of setting up the product from scratch was a huge challenge for us. That's when we came across WappSolutya's whitelabel solution. With a minimum initial investment that we made, the returns are insane. Plus, the product is so well made and is no doubt a viable business solution for years to come.''
          </ReadMoreReadLess>
        </div>
      </div>
      </div>
     
      <div className='flex flex-col lg:flex-row lg:w-4/12'>
      <img className="h-20 w-20 mx-auto" src={data.SD} alt="Sunset in the mountains"/>
      <div className="lg:w-10/12">
        <div className="lg:px-6 py-4 text-center lg:text-left">
          <div className="text-2xl mb-2">SD Media</div>
          <ReadMoreReadLess limit={200}>
        ''We had been trying to tap into the WhatsApp Official API solution for a while now. However, the technicalities of setting up the product from scratch was a huge challenge for us. That's when we came across WappSolutya's whitelabel solution. With a minimum initial investment that we made, the returns are insane. Plus, the product is so well made and is no doubt a viable business solution for years to come.''
          </ReadMoreReadLess>
        </div>
      </div>
      </div>
     
     </div>
     </div>

     <div className='faq my-[65px] lg:mt-[130px]'>
        <h1 className='text-4xl text-center'><b>FAQ's</b></h1>
        <div className='faqBox pt-[45px] lg:pt-[90px]'>
            <div className='flex flex-col mx-6 lg:mx-[11rem]'>
            <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 1}>
                  <AccordionHeader onClick={() => handleOpen(1)} className="faqHead  text-left text-base">
                  Do you provide a receipt for the payment?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                  Yes, receipts are provided after payment. You will be able to enter your company details & be able to download the receipt.
                  </AccordionBody>
                </Accordion>
              </Fragment> 
            <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 2}>
                  <AccordionHeader onClick={() => handleOpen(2)} className="faqHead  text-left text-base">
                  What Languages are supported? Does WappSolutya support Emojis?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                  WappSolutya supports all languages supported by Whatsapp including emoji's & smiley's to create impact for your content.
                  </AccordionBody>
                </Accordion>
              </Fragment> 
            <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 3}>
                  <AccordionHeader onClick={() => handleOpen(3)} className="faqHead  text-left text-base">
                  I have more questions. Where do I contact?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                  You can reach us by sending an email on contact@solutya.com. We will try our best to reply to you within 24-48 working hours.
                  </AccordionBody>
                </Accordion>
              </Fragment> 
            </div>
        </div>
        </div>

        <Updated/>

      </div> 
    </>
  )
}
