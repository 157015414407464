import "./App.css";
import "flowbite";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/landing page/home/Home";
import What from "./pages/landing page/what/What";
import Complete from "./pages/landing page/complete/Complete";
import Products from "./pages/landing page/products/Products";
import Demo from "./pages/landing page/demo/Demo";
import Email from "./pages/landing page/overEmail/Email";
import Experience from "./pages/landing page/experience/Experience";
import FAQ from "./pages/landing page/faq/FAQ";
import Bulk from "./pages/landing page/bulk/Bulk";
import Always from "./pages/landing page/always/Always";
import Blog from "./pages/landing page/blog/Blog";
import Let from "./pages/landing page/let/Let";
import Updated from "./pages/landing page/updated/Updated";
import Footer from "./pages/landing page/footer/Footer";
import Pricing from "./pages/pricing/Pricing";
import Reseller from "./pages/reseller/Reseller";
import SoftwareDemo from "./pages/software demo/SoftwareDemo";
import UserDashboard from "./pages/user/dashboard/UserDashboard";
import UserSidebar from "./components/UserSidebar";
import AdminSidebar from "./components/AdminSidebar";
import Profile from "./pages/user/profile/Profile";
import Order from "./pages/user/order/Order";
import Templete from "./pages/user/templete/Templete";
import AdminDashboard from "./pages/admin/adminDashboard/AdminDashboard";
import OrderDetails from "./pages/admin/orderDetails/OrderDetails";
import UsersProfile from "./pages/admin/usersProfile/UsersProfile";
import AdminProfile from "./pages/admin/adminProfile/AdminProfile";
import Payment from "./pages/pricing/Payment";
import BlogPage from "./pages/landing page/blog/BlogPage";
import AdminBlog from "./pages/admin/adminBlog/AdminBlog";
import AdminBlogView from "./pages/admin/adminBlog/AdminBlogView";
import Video from "./pages/landing page/Video/Video";
import Policy from "./pages/landing page/policy/Policy";
import Terms from "./pages/landing page/terms/Terms";
import UserFeedback from "./pages/user/feedback/UserFeedback";
import AdminFeedback from "./pages/admin/feedback/AdminFeedback";
import UsersList from "./pages/admin/usersList/UsersList";
import OrderList from "./pages/admin/orderList/OrderList";
import OrderDetail from "./pages/user/order/OrderDetail";
import DeliveredOrderList from "./pages/admin/orderList/DeliveredOrderList";
import ProcessingOrderList from "./pages/admin/orderList/ProcessingOrderList";
import Payments from "./pages/pricing/Payments";
import Login from "./pages/landing page/users/Login";
import Register from "./pages/landing page/users/Register";
import ProcessingOrderListDetails from "./pages/admin/orderList/ProcessingOrderListDetails";
import DeliveredOrderListListDetails from "./pages/admin/orderList/DeliveredOrderListListDetails";
import Test from "./pages/user/templete/Test";
import Header from "./pages/landing page/navbar/Header";
import DeliveredListPage from "./pages/admin/orderList/DeliveredListPage";
import ProcessingListPage from "./pages/admin/orderList/ProcessingListPage";

function App() {
  return (
    <>
      <Router>
        {/* <Heading /> */}
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <What />
                <Complete />
                <Products />
                <Demo />
                <Email />
                <Experience />
                <Video />
                <Blog />
                <FAQ />
                <Bulk />
                <Always />
                <Let />
                <Updated />
                <Footer />
                {/* <MessengerCustomerChat
                  pageId="120272619751630"
                  appId="<APP_ID>"
                  htmlRef="<REF_STRING>"
                /> */}
              </>
            }
          />

          <Route
            path="/pricing"
            element={
              <>
                <Pricing />
                <Footer />
              </>
            }
          />

          <Route
            path="/payment"
            element={
              <>
                <Payment />
                <Footer />
              </>
            }
          />

          <Route
            path="/payments/:planName"
            element={
              <>
                <Payments />
                <Footer />
              </>
            }
          />

          <Route
            path="/reseller"
            element={
              <>
                <Reseller />
                <Footer />
              </>
            }
          />

          <Route
            path="/softwareDemo"
            element={
              <>
                <SoftwareDemo />
                <Footer />
              </>
            }
          />

          <Route
            path="/blog"
            element={
              <>
                <BlogPage />
                <Footer />
              </>
            }
          />

          <Route
            path="/policy"
            element={
              <>
                <Policy />
                <Footer />
              </>
            }
          />

          <Route
            path="/terms"
            element={
              <>
                <Terms />
                <Footer />
              </>
            }
          />

          <Route
            path="/userDashboard"
            element={
              <>
                <UserSidebar>
                  <UserDashboard />
                </UserSidebar>
              </>
            }
          />

          <Route
            path="/userProfile"
            element={
              <>
                <UserSidebar>
                  <Profile />
                </UserSidebar>
              </>
            }
          />

          <Route
            path="/userOrder"
            element={
              <>
                <UserSidebar>
                  <Order />
                </UserSidebar>
              </>
            }
          />

          <Route
            path="/orderDetail/:id"
            element={
              <>
                <UserSidebar>
                  <OrderDetail />
                </UserSidebar>
              </>
            }
          />

          <Route
            path="/userTemplete"
            element={
              <>
                <UserSidebar>
                  <Templete />
                </UserSidebar>
              </>
            }
          />

          <Route
            path="/userFeedback"
            element={
              <>
                <UserSidebar>
                  <UserFeedback />
                </UserSidebar>
              </>
            }
          />

          <Route
            path="/adminDashboard"
            element={
              <>
                <AdminSidebar>
                  <AdminDashboard />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/orderDetails"
            element={
              <>
                <AdminSidebar>
                  <OrderDetails />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/usersList"
            element={
              <>
                <AdminSidebar>
                  <UsersList />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/orderList"
            element={
              <>
                <AdminSidebar>
                  <OrderList />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/deliveredOrderList"
            element={
              <>
                <AdminSidebar>
                  <DeliveredListPage />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/processingOrderList"
            element={
              <>
                <AdminSidebar>
                  <ProcessingListPage />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/processingOrderListDetails/:id"
            element={
              <>
                <AdminSidebar>
                  <ProcessingOrderListDetails />
                </AdminSidebar>
              </>
            }
          />
          <Route
            path="/test"
            element={
              <>
                <AdminSidebar>
                  <Test />
                </AdminSidebar>
              </>
            }
          />
          <Route
            path="/deliveredOrderListListDetails/:id"
            element={
              <>
                <AdminSidebar>
                  <DeliveredOrderListListDetails />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/usersProfile"
            element={
              <>
                <AdminSidebar>
                  <UsersProfile />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/adminProfile"
            element={
              <>
                <AdminSidebar>
                  <AdminProfile />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/adminBlog"
            element={
              <>
                <AdminSidebar>
                  <AdminBlog />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/adminBlogView/:id"
            element={
              <>
                <AdminSidebar>
                  <AdminBlogView />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/adminFeedback"
            element={
              <>
                <AdminSidebar>
                  <AdminFeedback />
                </AdminSidebar>
              </>
            }
          />

          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />

          <Route
            path="/register"
            element={
              <>
                <Register />
              </>
            }
          />
        </Routes>
        {/* <Footer/> */}
      </Router>
    </>
  );
}

export default App;
