import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";

export default function UserDashboard() {
  const [orders, setOrders] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    fetch(
      `https://busy-cyan-fox-suit.cyclic.app/api/v1/orders?email=${user?.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setOrders(data);
      });
  }, [user]);

  const { data: template, isLoading: templateLoading } = useQuery({
    queryKey: ["template"],
    queryFn: () =>
      fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/template").then(
        (res) => res.json()
      ),
  });

  // console.log(orders, "orders");

  if (templateLoading) return "Loading...";

  return (
    <>
      <div className="Dashboard space-y-6 mt-8 mx-4">
        <h1 className="text-2xl font-semibold">Dashboard</h1>

        <div className="flex space-x-5 pb-[30px]">
          <div className="w-[50%] space-y-5 ">
            <h1 className="text-xl font-semibold">Order Details</h1>
            {orders?.result?.map((item, index) => (
              <div className=" min-h-[205px] block  w-[100%] p-6  bg-white border border-gray-200 rounded-lg shadow-md ">
                <div className="relative space-y-2">
                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Order No: </b>
                    {index + 1}
                  </p>

                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Package Name : </b>
                    {item.planName}
                  </p>

                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Order Date : </b>
                    {item.createdAt?.slice(0, 10)}
                  </p>
                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Order Amount: </b>
                    {item.planAmount}
                  </p>
                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Order Validity : </b>
                    {item.planValidation}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="w-[50%] space-y-5">
            {/* <h1 className="text-xl font-semibold">Saved Templete</h1>
            {template.result.map((item, index) => (
              <div className="block min-h-[205px] w-[100%] p-6  bg-white border border-gray-200 rounded-lg shadow-md ">
                <div className="relative  space-y-2">
                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Template Number:</b> {index + 1}
                  </p>
                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Template Name: </b> {item.templateName}
                  </p>
                  <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                    <b>Template Text: </b> {item.templateText?.slice(0, 160)}
                  </p>
                </div>
              </div>
            ))} */}
            
          </div>
        </div>
      </div>
    </>
  );
}
