import React from 'react'
import { data } from '../../../utils/demo'

export default function Video() {
    
    return (
        <>
            <div className='video mt-[130px] py-[90px] bg-[#f7f7fc]'>
                <h1 className='text-4xl text-center mb-[90px]'><b>Video Tutorial</b></h1>
                <div className='flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-20 justify-center mx-6'>
                    <div>
                        <video width="500" height="300" poster={data.templete1} controls>
                            <source src={data.tutorial1} type="video/mp4"/>
                            "Your Browser dosen't support the video tag"
                        </video>
                    </div>
                    <div>
                        <video width="500" height="300" poster={data.templete2} controls>
                            <source src={data.tutorial1} type="video/mp4"/>
                            "Your Browser dosen't support the video tag"
                        </video>
                    </div>
                </div>
            </div>
        </>
    )
}
