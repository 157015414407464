import React from 'react'
import { data } from '../utils/demo';
import { useLocation } from "react-router-dom";

export default function UserSidebar({children}) {
  const { pathname } = useLocation();
  return (
    <>
    <div className='flex w-full'>
        <div>
        <aside className="w-40 h-full lg:w-56 border-r-2" aria-label="Sidebar">
  <div className="overflow-y-auto py-4 px-3 h-screen rounded dark:bg-gray-800">
    {/* <div className="flex flex-col lg:flex-row items-center pl-2.5 mb-5">
      <img src={data.logo} className="mr-3 h-8 sm:h-7" alt="Logo" />
      <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">WappSolutya</span>
    </div> */}
    <ul className="space-y-3 mt-10">
      <li style={{backgroundColor : pathname === "/userDashboard" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/userDashboard" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
          <i className="fa-sharp fa-solid fa-house text-[#b22ff0] w-5"></i>
          <span className="ml-3">Dashboard</span>
        </a>
      </li>
      <li style={{backgroundColor : pathname === "/userProfile" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/userProfile" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-user text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
        </a>
      </li>
      <li style={{backgroundColor : pathname === "/userTemplete" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/userTemplete" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-clipboard text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Templetes</span>
        </a>
      </li>
      <li style={{backgroundColor : pathname === "/userOrder" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/userOrder" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-cart-shopping text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">My Orders</span>
        </a>
      </li>
      <li style={{backgroundColor : pathname === "/userFeedback" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/userFeedback" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-comment-dots text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Feedback</span>
        </a>
      </li>
      <li>
        <a href="/" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-right-from-bracket text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Log Out</span>
        </a>
      </li>
      
    </ul>
  </div>
</aside>
        </div>
        <div className='w-full'>
            {children}
        </div>
    </div>
      
    </>
  )
}
