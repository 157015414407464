import { Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { data } from "../../utils/demo";

export default function Payment() {
  const [showModalQr, setShowModalQr] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [bkashSign, setbkashSign] = useState(true);
  const [nagadSign, setNagadSign] = useState(true);

  const onClick = () => {
    setShowModal(true);
  };

  const onClickQr = () => {
    setShowModalQr(true);
  };

  const onClose = () => {
    setShowModal(false);
  };
  const onCloseQr = () => {
    setShowModalQr(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col lg:flex-row items-start">
          <div className="space-y-4 lg:space-y-6 py-[45px] lg:py-[130px] px-6 lg:px-0 lg:pl-40 lg:w-1/2 ">
            <div>
              <h1 className="text-3xl">
                <b>Buy Wapp-Solutya Marketing Software Now!</b>
              </h1>
              <p className="my-2">
                You'll be redirected to a safe Payment Gateway.
              </p>
              <p className="text-[#FF5733] mt-2">
                * To Buy this Package,You must have an Account.*
              </p>
            </div>

            <div>
              <label
                htmlFor="countries"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                Country *
              </label>
              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
              >
                <option value="BD" selected>
                  Bangladesh
                </option>
                <option value="US">United States</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="currency"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                Currency *
              </label>
              <select
                id="currency"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="BDT" selected>
                  Bangladeshi Taka- BDT
                </option>
                <option value="USD">United States Dollar- USD</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                Email *
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Email"
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                Phone with Country Code *
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Email"
              />
            </div>

            <div>
              <label
                htmlFor="address"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                Billing Address *
              </label>
              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              ></textarea>
            </div>

            <div>
              <label
                htmlFor="city"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                City *
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="city"
                type="text"
                placeholder="Email"
              />
            </div>

            <div>
              <label
                htmlFor="city"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                State *
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="city"
                type="text"
                placeholder="Email"
              />
            </div>

            <div>
              <label
                htmlFor="city"
                className="block mb-2 text-md font-normal text-gray-900 dark:text-white"
              >
                Zip Code / Pin Code *
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="city"
                type="text"
                placeholder="Email"
              />
            </div>

            <div>
              <p className="text-lg text-gray-500">
                You get immediate access to the Software & Receipt with tax
                details (VAT) once payment is made.
              </p>
            </div>

            <div className="space-y-3">
              <React.Fragment>
                <button
                  onClick={onClick}
                  className="bg-[#b22ff0] text-white font-bold w-full py-2 px-4 rounded"
                >
                  PAY NOW &#62;
                </button>
                <Modal show={showModal} onClose={onClose}>
                  <Modal.Header>Payment Method</Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="flex justify-between">
                        <div className="font-bold">
                          Bkash Number:
                          <span className="text-[#798178] font-medium">
                            {" "}
                            01765654345
                          </span>{" "}
                        </div>
                        <div onClick={onClickQr}>
                          <i className="fa-sharp fa-solid fa-qrcode text-[#6A7469]"></i>
                        </div>
                      </div>
                      <div className="font-bold mt-5 mb-4">
                        Nagad Number:
                        <span className="text-[#798178] font-medium">
                          {" "}
                          01765654345
                        </span>{" "}
                      </div>
                    </div>
                    <hr />

                    <h5 className="flex justify-center mt-5">
                      Transaction Information
                    </h5>
                    <form>
                      <div className="mt-3 my-4">
                        <div className="mt-3">
                          <input
                            className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
                            id="email"
                            type="text"
                            placeholder="Transaction ID"
                          />
                        </div>
                        <div className="mt-3">
                          <input
                            className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                            id="email"
                            type="number"
                            placeholder="Mobile No"
                          />
                        </div>
                        <div className="mt-3">
                          <input
                            className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                            id="email"
                            type="email"
                            placeholder="Email"
                          />
                        </div>

                        <div className="mt-3">
                          <input
                            className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                            id="email"
                            type="number"
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                      <div className="flex justify-center p-2 bg-[#B22FF0] text-[#FFFFFF] font-bold">
                        <button type="submit">Submit</button>
                      </div>
                    </form>
                    <div className="flex flex-col lg:flex-row mt-3 space-y-6 lg:space-y-0 justify-center">
                      {/* {bkashSign && nagadSign  ?
                            <div className='space-y-5'>
                                
                                <div className="block mx-auto w-3/12 py-3 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl">
                                    <img onClick={()=> setbkashSign(false)} src={data.bkash} className="h-20 w-20 m-auto" alt=""/>
                                </div>
                                <div className="block mx-auto w-3/12 py-3 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl">
                                    <img onClick={()=> setNagadSign(false)} src={data.nagad} alt=""/>
                                </div>
                            </div>
                            : bkashSign === false ?
                            <div className="block mx-auto w-6/12 shadow-md hover:shadow-2xl">
                                    <img src={data.bkashQr} alt=""/>
                                    <div className='text-center'>
                                <button onClick={
                                    ()=> setbkashSign(true)
                                    } className="text-blue-700 hover:underline dark:text-blue-500">
                                        Go back
                                </button>
                            </div>
                            </div>
                            :
                            <div className="block mx-auto w-6/12 shadow-md hover:shadow-2xl">
                                    <img src={data.nagadQr} alt=""/>
                                    <div className='text-center'>
                                <button onClick={
                                    ()=> setNagadSign(true)
                                    } className="text-blue-700 hover:underline dark:text-blue-500">
                                        Go back
                                </button>
                                    </div>
                            </div>

                            } */}

                      <Modal show={showModalQr} onClose={onCloseQr}>
                        <Modal.Body className="flex justify-center">
                          <div className="flex justify-center  h-[500px] overflow-hidden">
                            <img
                              src={data.bkashQr}
                              alt="QR-code"
                              className="w-[100%]"
                            />
                          </div>
                        </Modal.Body>
                        <div className="flex justify-center pb-5">
                          <button
                            onClick={onCloseQr}
                            className="text-blue-700 hover:underline dark:text-blue-500 "
                          >
                            Go back
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </Modal.Body>
                </Modal>
              </React.Fragment>

              {/* <button className="text-black font-normal w-full py-2 px-4 rounded-[20px] border">Try Other Payment Mood</button> */}
              {/* <p className='ml-44'>More than 40 purchase in last 24 hours!</p> */}
            </div>
          </div>

          <div className="space-y-5 lg:pt-40 pl-6 lg:pl-20 lg:pr-40 lg:pb-20 sticky top-0">
            <div className="">
              <div className="block w-11/12 bg-white border border-gray-200  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="mb-2 px-6 py-3 text-3xl text-center text-white tracking-tight bg-[#1ea185]  dark:text-white">
                  Web WhatsApp Marketing - Basic Plan
                </h5>
                <p className="font-normal px-6 py-3 relative text-gray-700 dark:text-gray-400">
                  Plan Price:{" "}
                  <span className="absolute right-11 lg:right-28">
                    3000 taka
                  </span>{" "}
                </p>
                <p className="font-normal border-t-[2px] border-dotted px-6 py-3  relative text-gray-700 text-2xl dark:text-gray-400">
                  Total Price:
                  <span className="absolute right-3 lg:right-20">
                    3000 taka
                  </span>
                </p>
              </div>
            </div>

            <div className="space-y-3">
              <h1 className="text-xl">
                <b>Top Features</b>
              </h1>
              <p className="text-lg">
                Web WhatsApp Marketing - Basic Plan - Official WhatsApp API
              </p>
              <p className="text-lg">
                Support : Email: contact@solutya.com | WhatsApp : +880 1329-65-9900-(09)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
