import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

const OrderDetail = () => {
  const { id } = useParams();

  const {
    isLoading,
    data: singleuserDetails,
    error,
  } = useQuery({
    queryKey: ["userOrder"],
    queryFn: () =>
      fetch(
        `https://busy-cyan-fox-suit.cyclic.app/api/v1/orders/single?ord=${id}`
      ).then((res) => res.json()),
  });
  console.log(singleuserDetails, "single data");
  if (isLoading) return "Loading...";

  return (
    <div>
      <h2 className="mt-5 flex justify-center font-bold">Order Details</h2>
      <div className="mx-4 mt-5 block p-6  bg-white border border-gray-200 rounded-lg  ">
        <div className="relative space-y-2">
          <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
            <b>Order No: </b>
            {singleuserDetails?.result?._id}
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
            <b>Package Name : </b>
            {singleuserDetails?.result?.planName}
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
            <b>Order Date : </b>
            {singleuserDetails?.result?.createdAt?.slice(0, 10)}
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
            <b>Order Amount: </b>
            {singleuserDetails?.result?.planAmount}
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
            <b>Order Validity : </b>
            {singleuserDetails?.result?.planValidation}s
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
