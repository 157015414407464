import React, { useEffect } from 'react';
import DeliveredOrderList from './DeliveredOrderList';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from '@tanstack/react-query';
import auth from '../../../firebase.init';
import { FaCircleNotch } from 'react-icons/fa';
import ProcessingOrderList from './ProcessingOrderList';

const ProcessingListPage = () => {
    const [user, loading] = useAuthState(auth);

    // useEffect(() => {
    //   if (user?.email) {
    //     setAdminEmail(user?.email);
    //   }
    // }, [user.email]);
  
    const { data, isLoading, error, refetch } = useQuery({
      queryKey: ["Orders"],
      queryFn: () =>
        fetch(
          `https://busy-cyan-fox-suit.cyclic.app/api/v1/orders/all?email=solutyapvtltd@gmail.com`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
            },
          }
        ).then((res) => res.json()),
    });
  
    useEffect(() => {
      refetch();
    }, [data, refetch, user]);
  
    if (loading) return <div className="flex min-h-screen justify-center items-center"><FaCircleNotch className="animate-spin text-6xl text-center text-[#b22fef]"/></div>;
  
    if (isLoading) return <div className="flex min-h-screen justify-center items-center"><FaCircleNotch className="animate-spin text-6xl text-center text-[#b22fef]"/></div>;
    const ProcessingOrders = data?.result?.filter(
      (item) => item.status === "Processing"
    );
  
    
  
    const ProcessingData = {
      ProcessingOrders,
      refetch,
    };
    return (
        <div className="p-5 mt-4">
       <div>
        <ProcessingOrderList props={ProcessingData} />
      </div>
      </div>
    );
};

export default ProcessingListPage;