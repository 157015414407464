import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { FaCircleNotch } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function AdminDashboard() {
  const [totalUser, setTotalUser] = useState(0);

  // all data fetch (will organise later)
  useEffect(() => {
    fetch(`https://busy-cyan-fox-suit.cyclic.app/api/v1/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.result.length)
        setTotalUser(data.result.length);
      });
      
    }, []);
    //order fetching
    const { data:totalOrders, isLoading, error, refetch } = useQuery({
      queryKey: ["Orders"],
      queryFn: () =>
        fetch(
          `https://busy-cyan-fox-suit.cyclic.app/api/v1/orders/all?email=solutyapvtltd@gmail.com`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
            },
          }
        ).then((res) => res.json()),
    });
  

    // blogs data fetching
    const { data:totalblogs, isLoading:isBlogsLoading, error:blogsError } = useQuery({
      queryKey: ["blog"],
      queryFn: () =>
        fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/blog").then((res) =>
          res.json()
        ),
    });
    // console.log(totalOrders)
let totalProccessingOrder = totalOrders?.result.filter(item=> item.status==="Processing")
let totalDeliveredOrder = totalOrders?.result.filter(item=> item.status==="Succeed")
  return (
    <>
      <div>
        <div className="Dashboard space-y-6 mt-8 mx-4">
          {/* <h1 className='text-2xl font-semibold'>Dashboard</h1> */}

          <div className="">
            {/* <h1 className='text-xl font-semibold'><b>Total Users: </b> 32</h1> */}
          </div>

          <div className="space-y-5">
            {/* <h1 className='text-xl font-semibold'>Orders</h1> */}

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
              <div className="block p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow-sm shadow-[#B22FF0] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  <b>Total User: </b> {totalUser}
                </p>
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  Here is all User
                </p>
                <div className="flex justify-end">
                  <Link to="/usersList">
                    <button className="py-1 px-5 rounded-md bg-[#B22FF0] text-[#FFFFFF] font-bold ">
                      View
                    </button>
                  </Link>
                </div>
              </div>

              <div className="block p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow-sm shadow-[#B22FF0] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  <b>Total Order: </b>
                  {
                    isLoading? 
                   ( <FaCircleNotch className="animate-spin text-center text-[#b22fef] text-3xl my-10 w-full"/>)
                    :
                    <span>{totalOrders.result.length}</span>
                  } 
                </p>
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  Total Number of Order.{" "}
                </p>
                <div className="flex justify-end">
                  <Link to="/orderList">
                    <button className="py-1 px-5 rounded-md bg-[#B22FF0] text-[#FFFFFF] font-bold ">
                      View
                    </button>
                  </Link>
                </div>
              </div>

              <div className="block p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow-sm shadow-[#B22FF0] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  <b>Total Blog: </b>
                  {
                    isBlogsLoading? 
                   ( <FaCircleNotch className="animate-spin text-center text-[#b22fef]"/>)
                    :
                    <span>{totalblogs.result.length}</span>
                  } 
                </p>
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  Here is the Blog section.
                </p>
                <div className="flex justify-end">
                  <Link to="/adminBlog">
                    <button className="py-1 px-5 rounded-md bg-[#B22FF0] text-[#FFFFFF] font-bold ">
                      View
                    </button>
                  </Link>
                </div>
              </div>

              <div className="block p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow-sm shadow-[#B22FF0] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  <b>Total Delivered Order: </b> 
                  {
                    isLoading? 
                   ( <FaCircleNotch className="animate-spin text-center text-[#b22fef] text-3xl my-10 w-full"/>)
                    :
                    <span>{totalDeliveredOrder.length}</span>
                  } 
                </p>
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  Here is Total delivered Order.
                </p>
                <div className="flex justify-end">
                  <Link to="/deliveredOrderList ">
                    <button className="py-1 px-5 rounded-md bg-[#B22FF0] text-[#FFFFFF] font-bold ">
                      View
                    </button>
                  </Link>
                </div>
              </div>

              <div className="block p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow-sm shadow-[#B22FF0] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  <b>Total Processing Order: </b> 
                  {
                    isLoading? 
                   ( <FaCircleNotch className="animate-spin text-center text-[#b22fef] text-3xl my-10 w-full"/>)
                    :
                    <span>{totalProccessingOrder.length}</span>
                  }
                </p>
                <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide">
                  Here is Total Processing Order.
                </p>
                <div className="flex justify-end">
                  <Link to="/processingOrderList">
                    <button className="py-1 px-5 rounded-md bg-[#B22FF0] text-[#FFFFFF] font-bold ">
                      View
                    </button>
                  </Link>
                </div>
              </div>

              {/* <div className="block p-6 space-y-2 bg-white border border-gray-200 rounded-lg shadow-sm shadow-[#B22FF0] hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Total User: </b> 32</p>
      <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Order Validation: </b>0 years 3 months 4 days</p>
      <div className='flex justify-end'>
      <button className='py-1 px-5 rounded-md bg-[#B22FF0] text-[#FFFFFF] font-bold '>View</button>
      </div>
      </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
