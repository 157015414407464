import React, { useEffect } from 'react';
import { data } from '../../utils/demo';
import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react"; 
import Always from '../landing page/always/Always';
import Updated from '../landing page/updated/Updated';
import CountryCode from '../../components/CountryCode';
import { Link } from 'react-router-dom';

export default function SoftwareDemo() {

    const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(()=>{
    window.scrollTo(0,0)
   },[])

  return (
    <>
     <section className='softwareDemo'>
     {/* <img className='offerImg' src={data.offer} alt=""/> */}
     <section className='whatsappMarketing mt-[130px]'>
        <div className='flex flex-col lg:flex-row mx-20 lg:mx-44'>
            <div className='space-y-7'>
                <h1 className='text-4xl font-bold'>WhatsApp Marketing Software Free Demo</h1>
                <p className='text-xl text-gray-500'>Try out all our features for free before upgrading to a premium plan.</p>
            </div>
            <div className='bg-gray-100 lg:px-16 py-10 mt-20 ml-0 rounded-lg '>
            <form>

            <div className='flex flex-col lg:flex-row'>

                <div className='py-[15px] w-[200%] space-y-11 ml-5'>
                <div>
            <label htmlFor="name" className="block mb-2 font-medium">Name <span className='text-red-500'>*</span></label>
            <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-base w-[37%] lg:w-[100%] rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" required/>
            </div>
            <div>
            <label htmlFor="name" className="block mb-2 font-medium">Email <span className='text-red-500'>*</span></label>
            <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-base w-[37%] lg:w-[100%] rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email Address" required/>
            {/* <p className='text-xs mt-2'>You'll get an OTP.</p> */}
            </div>
                </div>

                <div className='py-[15px] w-[50%] space-y-11 ml-5'>
                <div className='w-[150%] lg:w-[100%]'>
            <label htmlFor="number" className="block mb-2 font-medium">Mobile <span className='text-red-500'>*</span></label>
            <CountryCode placeholder="Mobile Number" required/>
            </div>  
            <div>
            <label htmlFor="number" className="block mb-2 font-medium">Company Name <span className='text-red-500'>*</span></label>
            <input type="text" id="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-base w-[150%] lg:w-[100%] rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Company Name" required/>
            </div>
                </div>

            </div>
            <button className='homeButton homeButton1 block m-auto'>Start Demo</button>
            
        </form>
        <p className='mt-[1rem] text-center'>
        <small>No credit card details required.</small>
        </p>
            </div>
        </div>
     </section>

     <section className='Benefits mt-[130px]'>
        <h1 className='text-4xl text-center font-bold pb-[90px]'>Benefits Of WappSolutya WhatsApp Marketing Software</h1>
        <div className='flex flex-col lg:flex-row space-y-7 lg:space-y-0 lg:space-x-[40px] lg:mx-44'>
            <div className="block w-3/4 mx-auto lg:w-1/2 p-10 bg-gray-100 border border-gray-200 rounded-lg shadow-md">
                <img src={data.check} className="mx-auto" alt=""/>
                <p className="font-normal text-lg text-center text-gray-900 dark:text-gray-400">Send up to 1,00,000 messages per day from your own WhatsApp number.</p>
            </div>
            <div className="block w-3/4 mx-auto lg:w-1/2 p-10 bg-gray-100 border border-gray-200 rounded-lg shadow-md">
                <img src={data.check} className="mx-auto" alt=""/>
                <p className="font-normal text-lg text-center text-gray-900 dark:text-gray-400">No setup charges. Pay message charge directly to Facebook.</p>
            </div>
            <div className="block w-3/4 mx-auto lg:w-1/2 p-10 bg-gray-100 border border-gray-200 rounded-lg shadow-md">
                <img src={data.check} className="mx-auto" alt=""/>
                <p className="font-normal text-lg text-center text-gray-900 dark:text-gray-400">Get free 1000 messages every month.</p>
            </div>
        </div>
     </section>

     <section className='mt-[130px]'>
        <img className='mx-auto w-[85%] lg:w-[79%]' src={data.message} alt=""/>
     </section>

     <section className='faq pt-[65px] lg:pt-[130px]'>
        <h1 className='text-4xl text-center'><b>FAQ's</b></h1>
        <div className='faqBox pt-[45px] lg:pt-[90px]'>
            <div className='flex flex-col mx-6 lg:mx-[11rem]'>
            <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 1}>
                  <AccordionHeader onClick={() => handleOpen(1)} className="faqHead  text-left text-base">
                  Do you provide a receipt for the payment?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                  Yes, receipts are provided after payment. You will be able to enter your company details & be able to download the receipt.
                  </AccordionBody>
                </Accordion>
              </Fragment> 
            <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 2}>
                  <AccordionHeader onClick={() => handleOpen(2)} className="faqHead  text-left text-base">
                  What Languages are supported? Does WappSolutya support Emojis?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                  WappSolutya supports all languages supported by Whatsapp including emoji's & smiley's to create impact for your content.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 3}>
                  <AccordionHeader onClick={() => handleOpen(3)} className="faqHead  text-left text-base">
                  I have more questions. Where do I contact?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                  You can reach us by sending an email on contact@solutya.com. We will try our best to reply to you within 24-48 working hours.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>
        </div>
                </section>

                <Always/>

                <section className='let space-y-5 text-center mt-[130px] py-10'>
                    <h1 className='text-4xl text-white block m-auto'><b>Already tried the demo?</b></h1>
                    <div className='let-button space-x-6'>
                        <button className='text-[16px] text-[#B22FF0] font-medium border-[1px] border-solid border-white rounded-[25px] py-[10px] px-[25px] bg-white transition hover:text-white hover:bg-[#B22FF0]'><Link to="/pricing">Upgrade Now</Link></button>
                    </div>
                </section>

                <Updated/>
     </section> 
    </>
  )
}
