import React from 'react';
import { Link } from 'react-router-dom';

export default function Let() {
  return (
    <>
     <section className='let space-y-5 text-center mt-[130px] py-10'>
        <h1 className='text-4xl text-white block m-auto'><b>Let's grow your business</b></h1>
        <div className='let-button space-x-6'>
            <Link to="/softwareDemo"><button className='homeButton homeButton1'>Free Download</button></Link>
            <Link to="/pricing"><button className='letButton2'>Buy Now</button></Link> 
        </div>
     </section>
     
    </>
  )
}
