import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

export default function AdminBlogView() {
  const { id } = useParams();

  const { data: singleBlog, isLoading } = useQuery({
    queryKey: ["singleBlogs"],
    queryFn: () =>
      fetch(
        `https://busy-cyan-fox-suit.cyclic.app/api/v1/blog/single?blg=${id}`
      ).then((res) => res.json()),
  });

  if (isLoading) return "loading...";

  return (
    <>
      <div className="space-y-6 mt-8 mx-4">
        <h1 className="text-2xl font-semibold">Blog Details</h1>

        <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide text-lg">
          <b>Blog No: </b>1
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide text-lg">
          <b>Blog Name: </b>
          {singleBlog?.result?.blogHeadline}
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide text-lg">
          <b>Blog Post: </b> <br /> {singleBlog?.result?.blogText}
        </p>
      </div>
    </>
  );
}
