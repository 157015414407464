import React from 'react'
import { Link } from 'react-router-dom'
import { data } from '../../../utils/demo'

export default function Products() {
  return (
    <>

    <section className='products bg-[#f7f7fc] mt-[65px] lg:mt-[130px] py-[45px] lg:py-[90px] lg:px-[11rem]'>
    <h1 className='text-4xl text-center mb-[45px] lg:mb-[90px]'><b>Our Products</b></h1>
    <div className='space-y-[45px] lg:space-y-[90px]'>
      <div className='product-list-1 flex flex-col lg:flex-row justify-center items-center gap-6 lg:gap-2'>
        <div className=' w-3/4 lg:w-fit mx-auto'>
          <p className='text-3xl'><b>1. Web WhatsApp Marketing Software</b></p>
          <p className='mt-5 text-xl' style={{lineHeight: "163%"}}>A great solution for small & large businesses to send up to 100,000 messages per day. Get access to WhatsApp green tick as well.</p>
          <button className='homeButton homeButton1 mt-7'><Link to="pricing">Know More</Link></button>
        </div>
        <div className='mx-6 lg:mx-0 lg:ml-10'>
          <img src={data.prod1} alt="productImage" className='max-w-[320px] md:max-w-[500px]lg:max-w-[600px]' />
        </div>
      </div>
      <div className='product-list-2 flex flex-col lg:flex-row justify-center items-center gap-6 lg:gap-2'>'>
      <div className='mx-6 lg:mx-0'>
          <img src={data.prod2} alt="productImage" className='max-w-[320px] md:max-w-[500px]lg:max-w-[600px]'/>
        </div>
        <div className='w-3/4 lg:w-fit mx-auto lg:pl-10 '>
          <p className='text-3xl'><b>2. WhatsApp Marketing Software With Whitelabel For Reselling</b></p>
          <p className='mt-5 text-xl' style={{lineHeight: "163%"}}>Sell Whitelabel WhatsApp marketing platform to your customers at your own set prices. Unlimited licenses included.</p>
          <button className='homeButton homeButton1 mt-7'><Link to="reseller">Know More</Link></button>
        </div>
      </div>
    </div>
    </section>

    <section className='amazing mt-[130px] mx-6 lg:mx-0'>
    <h1 className='text-4xl text-center'><b>Amazing features to grow your business</b></h1>
    <img src={data.features} alt=""/>
    </section>

    </>
  )
}
