import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router";

const DeliveredOrderListListDetails = () => {
  const { id } = useParams();
  console.log(id);
  const { data: singleOrder, isLoading } = useQuery({
    queryKey: ["singleOrder"],
    queryFn: () =>
      fetch(
        `https://busy-cyan-fox-suit.cyclic.app/api/v1/orders/single?ord=${id}`
      ).then((res) => res.json()),
  });

  const handleDelete = (id) => {
    console.log(id);
    fetch(`https://busy-cyan-fox-suit.cyclic.app/api/v1/orders?ord=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });
  };

  if (isLoading) return "loading...";

  return (
    <div className="mt-10">
      <h2 className="text-[20px] font-bold flex ml-5 ">Order Details</h2>
      <div className="mx-5 mt-5 space-y-4">
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold"> Plan Type: </h2>
          <p>{singleOrder?.result?.planName}</p>
        </div>
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold"> Purchased Email: </h2>
          <p>{singleOrder?.result?.email}</p>
        </div>
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold"> Purchase Date: </h2>
          <p>{singleOrder?.result?.createdAt?.slice(0, 10)}</p>
        </div>
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold"> User Email: </h2>
          <p>{singleOrder?.result?.userEmail}</p>
        </div>
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold">Status: </h2>
          <p>{singleOrder?.result?.status}</p>
        </div>
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold">TAXID: </h2>
          <p>
            {singleOrder?.result?.txid ||
              singleOrder?.result?.bankTransactionId}
          </p>
        </div>
        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold">Method: </h2>
          <p>{singleOrder?.result?.method}</p>
        </div>

        <div className="flex gap-5">
          <h2 className="text-[16px] font-bold">Amount: </h2>
          <p>{singleOrder?.result?.amount}</p>
        </div>
      </div>
    </div>
  );
};

export default DeliveredOrderListListDetails;
