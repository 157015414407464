import React from 'react';
import { data } from '../../../utils/demo';
import { Link } from 'react-router-dom';
// import ReactDOM from 'react-dom';
// import Countdown from 'react-countdown';

export default function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <>
     <section className='footer text-white bg-[#150937]'>
      <div className='h-10'></div>
      <div className='flex flex-col lg:flex-row lg:space-x-16 py-5 text-center text-base font-semibold bg-white text-[#150937] w-[75%] h-[100%] justify-center mx-auto rounded-lg'>
        <div className='relative text-center h-40 space-y-5'>
        <i className="fa-sharp fa-solid fa-comments text-3xl"></i>
        <p>Mon-Sat 10:00 - 19:00 hrs BST <br/> Pre sale queries</p>
        
        <p className=' text-blue-500'>
        <a href="tel:+880 1329-65-9900">+880 1329-65-9900-(09)
        </a></p>
        </div>
        <div className='relative text-center h-40 space-y-5'>
        <i className="fa-sharp fa-solid fa-phone text-3xl"></i>
        <p>Mon-Sat 10:00 - 19:00 hrs BST <br/> Post sale queries</p>
        <p className='text-blue-500'>
        <a href="tel:+880 9611-656-145">+880 9611-656-145
        </a></p>
        </div>
        <div className='relative text-center h-40 space-y-5'>
        <i className="fa-sharp fa-solid fa-display text-3xl"></i>
        <p>We'll show you how it works.</p>
        <p className='lg:absolute lg:bottom-4 lg:left-9  text-blue-500'>
          <Link to="/softwareDemo">Request a Demo</Link> 
          </p>
        </div>
      </div>

      <div className='flex flex-col sm:flex-row px-8 md:ml-24 lg:ml-0 space-x-0 lg:space-x-28 space-y-10 lg:space-y-6 justify-center py-10'>

        <div className='space-y-2'>
          <img src={data.whiteLogo} className="h-[6rem]" alt=""/>
          <ul>
            <li className='mb-7'>
            <i className="fa-solid fa-envelope mr-2"></i>
            contact@solutya.com
            </li>
            <li className='mb-3'>
              Follow Us
            </li>
            <li className='space-x-6 mb-2'>
            <a href='https://www.facebook.com/Solutya' target="_blank" rel="noreferrer"><i className='fa-brands fa-facebook text-xl'></i></a>
            <a href='https://www.instagram.com/solutya/' target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram text-xl"></i></a>
            <a href='https://www.linkedin.com/company/solutya/mycompany/' target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin text-xl"></i></a>
            </li>
            <li className='space-x-6'>
            <a href='https://twitter.com/SolutyaI' target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter text-xl"></i></a>
            <a href='https://www.behance.net/solutyapvtltd' target="_blank" rel="noreferrer"><i className="fa-brands fa-behance text-xl"></i></a>
            <a href='https://dribbble.com/Solutya' target="_blank" rel="noreferrer"><i className="fa-solid fa-basketball text-xl"></i></a>
            </li>
          </ul>
        </div>

        <div className='space-y-4 lg:space-y-10'>
        <h1 className='text-2xl'><b>Links</b></h1>
          <ul className='space-y-5 lg:space-y-5'>
            <li>
              <Link to="/softwareDemo">WhatsApp Marketing Demo</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link> 
            </li>
            <li>
              
            </li>
          </ul>
        </div>

        <div className='space-y-4 lg:space-y-10'>
        <h1 className='text-2xl'><b>Services</b></h1>
          <ul className='space-y-5'>
            <li><a href='https://solutya.com/services#' target="_blank" rel="noreferrer">Strategy & Research</a></li>
            <li><a href='https://solutya.com/services#' target="_blank" rel="noreferrer">Web Development</a></li>
            <li><a href='https://solutya.com/services#' target="_blank" rel="noreferrer">ERP Solution</a></li>
            <li><a href='https://solutya.com/services#' target="_blank" rel="noreferrer">App Development</a></li>
            <li><a href='https://solutya.com/services#' target="_blank" rel="noreferrer">SEO & Marketing</a></li>
            <li><a href='https://solutya.com/services#' target="_blank" rel="noreferrer">24X7 Support</a></li>
          </ul>
        </div>

        <div className='space-y-4 lg:space-y-10'>
        <h1 className='text-2xl'><b>Browse</b></h1>
          <ul className='space-y-5'>
            <li>
              <a href="/">Home</a> 
            </li>
            <li>
              <a href="/">About Us</a> 
            </li>
            <li>
              <a href="/">Contact Us</a>
            </li>
            <li>
              <Link to="/terms">Terms of Service</Link> 
            </li>
            <li>
              <Link to="/policy">Privacy Policy</Link>  
            </li>
          </ul>
        </div>

      </div>

      <div className='space-y-7 py-8 px-4 lg:px-60 lg:py-10 text-center'>
      <hr />
      <div><b>Disclaimer: <span style={{fontWeight: "500"}}>WappSolutya is not affiliated or a part of WhatsApp™, Facebook Inc., or Meta. WappSolutya is an independent web-portal and has no affiliation with Facebook, WhatsApp, and Instagram, or any such related products.</span></b></div>
      <div className='footer-copyright'>{currentYear} © Copyright by <Link to='/' className='text-blue-500'>wapp.solutya.com</Link>. All Rights Reserved by <a href='https://solutya.com/' className='text-blue-500' target='_blank' rel='noopener noreferrer'>Solutya Private Limited</a>
.</div>
      </div>
      </section>
    </>
    
  )
  // ReactDOM.render(
  //   <Countdown
  //     date={Date.now() + 5000}
  //     renderer={renderer}
  //   />,
  //   document.getElementById('root')
  // );
}
