import React, { useState } from "react";
import { Link } from "react-router-dom";
import { data } from "../utils/demo";

export default function SoftwarePricing() {
  const [country, setCountry] = useState("BD");
  const [currency, setCurrency] = useState("BDT");
  const [currencySign, setCurrencySign] = useState("৳");

  const [basicAmount, setBasicAmount] = useState(1499.0);
  const [standardAmount, setStandardAmount] = useState(2999.0);
  const [professionalAmount, setProfessionalAmount] = useState(5999.0);
  const [whiteYearly, setWhiteYearly] = useState(9999.0);
  const [whiteLifetime, setWhiteLifetime] = useState(44999.0);

  const handleChange = () => {
    if (country === "BD") {
      setCountry("US");
      setCurrency("United States Dollar - USD");
      setCurrencySign("$");
      setBasicAmount((basicAmount * 0.0098).toFixed(2));
      setStandardAmount((standardAmount * 0.0098).toFixed(2));
      setProfessionalAmount((professionalAmount * 0.0098).toFixed(2));
      setWhiteYearly((whiteYearly * 0.0098).toFixed(2));
      setWhiteLifetime((whiteLifetime * 0.0098).toFixed(2));
    } else if (country === "US") {
      setCountry("BD");
      setCurrency("Bangladeshi Taka - BDT");
      setCurrencySign("৳");
      setBasicAmount((basicAmount / 0.0098).toFixed(0));
      setStandardAmount((standardAmount / 0.0098).toFixed(0));
      setProfessionalAmount((professionalAmount / 0.0098).toFixed(0));
      setWhiteYearly((whiteYearly / 0.0098).toFixed(0));
      setWhiteLifetime((whiteLifetime / 0.0098).toFixed(0));
    }
  };

  const planValue = {
    planName: "Basic",
    planAmount: basicAmount,
    planSine: currencySign,
    planValidation: "Plan is valid for one year and for 1 PC",
  };

  const planValue2 = {
    planName: "Standard",
    planAmount: standardAmount,
    planSine: currencySign,
    planValidation: "Plan is valid for a lifetime and for 1 pc",
  };
  const planValue3 = {
    planName: "Professional",
    planAmount: professionalAmount,
    planSine: currencySign,
    planValidation: "Plan is valid for one year and for 5 PC",
  };

  return (
    <>
      <div className="">
        <h1 className="text-4xl mt-[65px] lg:mt-[130px] text-center font-medium">
          <b>WhatsApp Marketing Software Pricing</b>
        </h1>

        <div className="space-y-8 lg:space-y-5 my-[45px] lg:my-[90px] mx-7 lg:mx-0 justify-center">
          <div className="lg:w-4/12 lg:mx-auto">
            <div className="toggle-btn space-x-3 text-center w-full">
              <span className="">Bangladesh</span>
              <label onChange={handleChange} className="switch">
                <input type="checkbox" id="checkbox" />
                <span className="slider round"></span>
              </label>
              <span>USA</span>
            </div>
          </div>

          <div className="flex space-x-3 lg:w-4/12 lg:mx-auto">
            <label
              htmlFor="currency"
              className="block my-2 text-base font-medium dark:text-white ml-20 lg:ml-28"
            >
              Currency
            </label>
            <input
              type="text"
              id="currency"
              value={currency}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[45%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              readOnly
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-col lg:flex-row space-y-10 lg:space-y-0 space-x-0 lg:space-x-[30px] mx-0 lg:mx-30 justify-center">
          <div>
            <div className="block w-[26rem] lg:h-[42rem] w-[20rem] lg:w-[23rem] py-6 px-10 space-y-7 text-center mx-auto lg:mx-0 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 dark:text-white">
                {planValue.planName}
              </h5>
              <div className="w-3/4 mx-auto pl-16 text-left ">
                <p className="font-normal text-black dark:text-gray-400 space-y-2">
                  <p className="text-2xl">{currencySign}</p>{" "}
                  <p className="text-5xl">{planValue.planAmount}</p>{" "}
                  <p>Yearly / 1PC</p>
                </p>
              </div>
              <Link
                to={`/payments/${planValue.planName}`}
                // to={`/payments/${planValue.planName}?price=${planValue.planAmount}&sign=${planValue.planSine}&valid=${planValue.planValidation}`}
              >
                <button className="homeButton homeButton1 mt-3 w-52">
                  Buy Now
                </button>
              </Link>
              <div className="space-y-1">
                <p className="text-base font-bold text-left">
                  {planValue.planValidation}
                </p>
                <p className="text-xs text-red-600 text-left">
                  * <i>Excludes Vat & Tax</i>{" "}
                </p>
              </div>
              <ul className="space-y-4 text-sm text-left">
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Contact Management
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Run campaigns
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  500 Contacts Import
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  API credentials
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Setup fee included
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Direct phone support
                </li>
              </ul>
              <div className="space-y-3">
                <p>Start Free Demo</p>
                <p className="text-xs">No credit card details required</p>
              </div>
            </div>
          </div>

          <div>
            <div className="block w-[26rem] lg:h-[42rem] relative w-[20rem] lg:w-[23rem] py-6 px-10 space-y-6 text-center mx-auto lg:mx-0 bg-red-50 border border-gray-200 rounded-lg shadow-md hover:bg-red-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <img src={data.popular} alt="" className="absolute top-0" />
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 dark:text-white">
                {planValue2.planName}
              </h5>
              <div className="w-3/4 mx-auto pl-16 text-left ">
                <p className="font-normal text-black dark:text-gray-400 space-y-2">
                  <p className="text-2xl">{currencySign}</p>{" "}
                  <p className="text-5xl">{planValue2.planAmount}</p>{" "}
                  <p>Lifetime / 1PC</p>
                </p>
              </div>
              <Link
                to={`/payments/${planValue2.planName}`}
                // to={`/payments/${planValue2.planName}?price=${planValue2.planAmount}&sign=${planValue2.planSine}&valid=${planValue2.planValidation}`}
              >
                <button className="homeButton homeButton1 mt-3 w-52">
                  Buy Now
                </button>
              </Link>
              <div className="space-y-1">
                <p className="text-base font-bold text-left">
                  {planValue2.planValidation}
                </p>
                <p className="text-xs text-red-600 text-left">
                  * <i>Excludes Vat & Tax</i>{" "}
                </p>
              </div>
              <ul className="space-y-4 text-sm text-left">
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Contact Management
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Run campaigns
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Unlimited Contacts Import
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  API credentials
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Setup fee included
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Direct phone support
                </li>
              </ul>
              <div className="space-y-3">
                <p>Start Free Demo</p>
                <p className="text-xs">No credit card details required</p>
              </div>
            </div>
          </div>

          <div>
            <div className="block  w-[26rem] lg:h-[42rem] w-[20rem] lg:w-[23rem] py-6 px-10 space-y-6 text-center mx-auto lg:mx-0 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 dark:text-white">
                {planValue3.planName}
              </h5>
              <div className="w-3/4 mx-auto pl-16 text-left ">
                <p className="font-normal text-black dark:text-gray-400 space-y-2">
                  <p className="text-2xl">{currencySign}</p>{" "}
                  <p className="text-5xl">{planValue3.planAmount}</p>{" "}
                  <p>Yearly / 5PC</p>
                </p>
              </div>
              <Link
                to={`/payments/${planValue3.planName}`}
                // to={`/payments/${planValue3.planName}?price=${planValue3.planAmount}&sign=${planValue3.planSine}&valid=${planValue3.planValidation}`}
              >
                <button className="homeButton homeButton1 mt-3 w-52">
                  Buy Now
                </button>
              </Link>
              <div className="space-y-1">
                <p className="text-base font-bold text-left">
                  {planValue3.planValidation}
                </p>
                <p className="text-xs text-red-600 text-left">
                  * <i>Excludes Vat & Tax</i>{" "}
                </p>
              </div>
              <ul className="space-y-4 text-sm text-left">
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Contact Management
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Run campaigns
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  500 Contacts Import
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  API credentials
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Setup fee included
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Direct phone support
                </li>
              </ul>
              <div className="space-y-3">
                <p>Start Free Demo</p>
                <p className="text-xs">No credit card details required</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className='flex flex-col sm:flex-col lg:flex-row space-y-10 lg:space-y-0 sm:space-x-0 lg:space-x-[40px] mx-5 lg:mx-10 justify-center my-[90px]'>

<div>
<div className="block lg:h-[45rem] w-[22rem] lg:w-[35rem] py-6 px-10 space-y-6 text-center mx-auto lg:mx-0 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
     <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 dark:text-white">WHITE LABEL SOFTWARE - YEARLY</h5>
     <div className='w-3/4 mx-auto pl-10 lg:pl-28 text-left '>
     <p className="font-normal text-black dark:text-gray-400 space-y-2"><p className='text-2xl'>{currencySign}</p> <p className='text-5xl'>{whiteYearly}</p> <p>Lifetime</p></p>
     </div>
     <Link to="/payment">
     <button className='homeButton homeButton1 mt-3 w-52'>Buy Now</button>
     </Link>
     <div className=''>
     <p className='text-base text-left'>This pricing plan is valid for lifetime and for 5 PC. <b>After purchase just upload your logo and name to modify the software &</b> resell WappSolutya with your company name immediately.</p>
     <Link to="/reseller"><p className='my-5 text-blue-500'>View Reseller Page</p></Link> 
     </div>
     <ul className='space-y-4 text-sm text-left'>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     WhatsApp marketing software platform
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Unlimited reselling license
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Whitelabel with your logo and company name
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Free updates, emails and chat support
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Setup within 3 days of order
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     License management
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Set your own price to resell
     </li>
   </ul>
   </div>
 </div>

<div>
   <div className="block lg:h-[45rem] w-[22rem] lg:w-[35rem] py-6 px-10 space-y-6 text-center mx-auto lg:mx-0 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
     <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 dark:text-white">WHITE LABEL SOFTWARE - LIFETIME</h5>
     <div className='w-3/4 mx-auto pl-10 lg:pl-28 text-left '>
     <p className="font-normal text-black dark:text-gray-400 space-y-2"><p className='text-2xl'  >{currencySign}</p> <p className='text-5xl'>{whiteLifetime}</p> <p>Lifetime</p></p>
     </div>
     <Link to="/payment">
     <button className='homeButton homeButton1 mt-3 w-52'>Buy Now</button>
     </Link>
     <div className=''>
     <p className='text-base text-left'>This pricing plan is valid for a lifetime on multiple PC. <b>After purchase just upload your logo and name to modify the software &</b> resell WappSolutya with your company name immediately.</p>
     <Link to="/reseller"><p className='my-5 text-blue-500'>View Reseller Page</p></Link> 
     </div>
     <ul className='space-y-4 text-sm text-left'>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     WhatsApp marketing software platform
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Unlimited reselling license
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Whitelabel with your logo and company name
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Free updates, emails and chat support
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Setup within 3 days of order
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     License management
     </li>
     <li>
     <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
     Set your own price to resell
     </li>
   </ul>
   </div>
 </div>

</div> */
}
