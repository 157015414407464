import React from 'react';
import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

export default function Bulk() {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };
  return (
    <>
     <section className='bulk pb-[65px] bg-[#f7f7fc]'>
     <h1 className='text-4xl text-center mx-6 lg:mx-0'><b>Bulk WhatsApp Sender Tips & Tricks</b></h1>

     <div className='bulkBox pt-[45px] lg:py-[90px] mx-10 lg:mx-0'>

        <div className='flex'>
        <div className='flex flex-col w-full lg:w-2/4 lg:ml-[11rem] mr-5'>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 1}>
                  <AccordionHeader onClick={() => handleOpen(1)} className="faqHead  text-left text-base">
                  How does WhatsApp marketing work?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "110px"}}>
                  The Bulk WhatsApp Sender uses the official WhatsApp cloud API and then sends messages using it directly. This is a 100% fully compliant tool with WhatsApp policy.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 2}>
                  <AccordionHeader onClick={() => handleOpen(2)} className="faqHead  text-left text-base">
                  Is WhatsApp marketing legal? Is WhatsApp marketing legal in India?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "170px"}}>
                  Sending bulk messages via WhatsApp is not illegal - in fact, sending message is most important use-case of WhatsApp. However, sending spam messages to an unknown recipient is not allowed. WhatsApp can possibly block the account from which the messages are sent. With WappSolutya, you get an official way to send bulk WhatsApp messages.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 3}>
                  <AccordionHeader onClick={() => handleOpen(3)} className="faqHead  text-left text-base">
                  Can we send bulk messages from WhatsApp marketing tool? How can I send bulk WhatsApp messages without getting banned?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "120px"}}>
                  Instead of sending bulk messages, you should try to send genuine messages to users that can help you in increasing your revenue. Because the tool can be easily customized as per the needs of the user.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 4}>
                  <AccordionHeader onClick={() => handleOpen(4)} className="faqHead  text-left text-base">
                  What is bulk WhatsApp Sender?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "290px"}}>
                  Broadcasting messages but not getting any results? It's time to make a switch to WappSolutya. This WhatsApp broadcast software has its ways to make your messages reach your targeted audience at the right place and time while bringing in the desired results and growth you wish to achieve in your business.
                  WappSolutya is a bulk sending tool to help you automate your marketing. It helps you in quickly sending messages to a large number of users. These messages are send one-to-one which means that your messages are guaranteed to be delivered unlike sending via Broadcast. The functionality is achieved with the help of automating the Web.WhatsApp.com platform.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 5}>
                  <AccordionHeader onClick={() => handleOpen(5)} className="faqHead  text-left text-base">
                  Can we use WhatsApp for marketing?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "200px"}}>
                  Yes, you can use it for marketing subject to certain conditions like not sending a single message to a user more than once a day. You should use it sparingly unlike SMS or Email marketing as it is high as the engagement rate. Across the world, many companies are using it for promotions and marketing. In-fact, restaurants, events, and fashion shops are using it in a huge manner to drive revenue for their business.
                  </AccordionBody>
                </Accordion>
              </Fragment>
        </div>

        <div className='flex flex-col w-full lg:w-2/4 lg:mr-[11rem] lg:ml-5'>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 6}>
                  <AccordionHeader onClick={() => handleOpen(6)} className="faqHead  text-left text-base">
                  What is WhatsApp sender pro?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "120px"}}>
                  WhatsApp sender pro was the original manufacturer of the WhatsApp marketing software. Later on, WappSolutya formed a different fork of it and started a separate business.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 7}>
                  <AccordionHeader onClick={() => handleOpen(7)} className="faqHead  text-left text-base">
                  How much does WhatsApp business cost? Is WhatsApp business free to use?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "170px"}}>
                  WhatsApp Business is absolutely free to use and was specifically build for small and medium businesses to communicate with their customers. For businesses, it's a wonderful app to personally connect with customers & solve their grievances if any. It can also be used to highlight the products and services along with answering their questions.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 8}>
                  <AccordionHeader onClick={() => handleOpen(8)} className="faqHead  text-left text-base">
                  Which software is used for WhatsApp Marketing?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "170px"}}>
                  There are many software available in the market for WhatsApp marketing. However, the problem with most of them is that they are not regularly updated. As a result, they stop working after some time when WhatsApp updates its app. WappSolutya on the other hand provides software that is regularly updated and hence it is the only recommended software to buy.
                  </AccordionBody>
                </Accordion>
        </Fragment>
        </div>
        </div>
     </div>
        
    </section> 
    </>
  )
}
