import React from 'react'
import { data } from '../utils/demo';
import { useLocation } from "react-router-dom";

export default function AdminSidebar({children}) {
  const { pathname } = useLocation();
  return (
    <>
    <div className='flex w-full'>
        <div>
        <aside className="w-40 h-full lg:w-56 border-r-2" aria-label="Sidebar">
          <div className="overflow-y-auto py-4 px-3 h-screen rounded dark:bg-gray-800">
            {/* <div className="flex flex-col lg:flex-row items-center pl-2.5 mb-5">
      <img src={data.logo} className="mr-3 h-8 sm:h-7" alt="Logo" />
      <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">WappSolutya</span>
    </div> */}
    <ul className="space-y-3 mt-10">

      <li style={{backgroundColor : pathname === "/adminDashboard" ? "#F3F4F6" : pathname === "/orderDetails" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/adminDashboard" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
          <i className="fa-sharp fa-solid fa-house text-[#b22ff0] w-5"></i>
          <span className="ml-3">Dashboard</span>
        </a>
      </li>

      <li style={{backgroundColor : pathname === "/adminProfile" ? "#F3F4F6" : pathname === "/usersProfile" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/adminProfile" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-user text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
        </a>
      </li>

      <li style={{backgroundColor : pathname === "/usersList" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/usersList" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-list text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">User List</span>
        </a>
      </li>

      <li style={{backgroundColor : pathname === "/orderList" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/orderList" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-list text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Order List</span>
        </a>
      </li>

      <li style={{backgroundColor : pathname === "/adminBlog" ? "#F3F4F6" : pathname === "/adminBlogView" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/adminBlog" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-blog text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3">Blog</span>
        </a>
      </li>
      <li style={{backgroundColor : pathname === "/adminFeedback" ? "#F3F4F6" : "inherit", borderRadius: "0.5rem"}}>
        <a href="/adminFeedback" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-comment-dots text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Feedback</span>
        </a>
      </li>
      <li>
        <a href="/" className="flex items-center px-2 py-5 text-base lg:text-lg font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
        <i className="fa-sharp fa-solid fa-right-from-bracket text-[#b22ff0] w-5"></i>
          <span className="flex-1 ml-3 whitespace-nowrap">Log Out</span>
        </a>
      </li>
      
    </ul>
  </div>
</aside>
        </div>
        <div className='w-full'>
            {children}
        </div>
    </div>
      
    </>
  )
}
