import React from 'react'
import { data } from '../../../utils/demo'

export default function Email() {
  return (
    <>
    <section className='bg-[#f7f7fc] py-[90px] mt-[130px]'>
    <section className='email flex flex-col lg:flex-row justify-center items-center gap-6 mx-36'>
        <div className='mx-8 space-y-6 lg:flex-1'>
        <h1 className='text-4xl'>
            <b>Why Use Whatsapp Over Email?</b>
        </h1>
        <p className='text-xl'>WhatsApp is the most powerful communication channel in the market.</p>
        <p className='text-xl'>As per statitics, when you send 100 WhatsApp messages almost 99% of these messages will be read and more than 40% users will click the link. This makes it the most engaging platform.</p>
        </div>
        <div className='mx-8 lg:mx-0 lg:flex-1 '>
          <img src={data.over} alt="whatsappemail"/>
        </div>
    </section>

    <section className='send mt-[45px] lg:mt-[90px]'>
      <img src={data.send} alt="" className='mx-auto w-[85%] lg:w-[79%]'/>
    </section>

    <section className='freeMessage mt-[130px] mb-5'>
      <img src={data.message} className="mx-auto w-[85%] lg:w-[79%]" alt="" />
      </section>
    </section>
     
    </>
  )
}
