import React from "react";
import { Link } from "react-router-dom";
const DeliveredOrderList = ({ props }) => {
  // console.log(props, "props");
  return (
    <div className="mt-8">
      <h1 className="text-2xl font-semibold flex justify-center">
        Delivered Order Lists
      </h1>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6 text-center">
                SL
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Name
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Plan Type
              </th>
              <th scope="col" className="py-3 px-6">
                Email
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Status
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Purchase Date
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Amount
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                View
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {props?.map((item, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="py-4 px-6  text-center">{index + 1}</td>
                <td className="py-4 px-6 text-center">Farhan</td>
                <td className="py-4 px-6 text-center">{item.planName}</td>
                <td className="py-4 px-6 ">{item.email}</td>
                <td className="py-4 px-6 text-[#23A90D] text-center font-bold">
                  {item.status}
                </td>
                <td className="py-4 px-6 text-center">
                  {item.createdAt?.slice(0, 10)}
                </td>
                <td className="py-4 px-6 text-center">{item.amount}</td>
                <Link to={`/deliveredOrderListListDetails/${item._id}`}>
                <td className="py-4 px-6 text-center">
                  <i className="fa-regular fa-eye"></i>
                </td>
                </Link>
                <td className="py-4 px-6 text-center">
                  <button className="font-medium text-red-600 dark:text-red-500 ">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DeliveredOrderList;
