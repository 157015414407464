import { Button, Label, TextInput } from "flowbite-react";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";

export default function UserFeedback() {
  const [user] = useAuthState(auth);

  const userFeedbackHandler = (e) => {
    e.preventDefault();
    const clientName = e.target.clientName.value;
    const designation = e.target.designation.value;
    const userFeedbackImages = e.target.userFeedbackImages.value;
    const userFeedbackText = e.target.userFeedbackText.value;

    const finalData = {
      clientName,
      designation,
      feedbackImage: userFeedbackImages,
      feedbackText: userFeedbackText,
      userEmail: user?.email,
    };

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/admin-feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });
  };
  return (
    <>
      <div>
        <div className="Feedback space-y-6 mt-8 mx-4">
          <h1 className="text-2xl font-semibold">Feedback</h1>
          <div className="space-y-3 px-6 pb-4 sm:pb-6 lg:px-2 xl:pb-8">
            <form onSubmit={userFeedbackHandler}>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="clientName" value="Client Name *" />
                </div>
                <TextInput
                  type="text"
                  name="clientName"
                  id="clientName"
                  placeholder="Name"
                  required={true}
                  className="lg:w-3/12"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="designation" value="Designation *" />
                </div>
                <TextInput
                  type="text"
                  name="designation"
                  id="designation"
                  placeholder="Designation"
                  required={true}
                  className="lg:w-3/12"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="userFeedbackImaget"
                    value="Insert Image"
                    className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                  />
                </div>
                <TextInput
                  id="userFeedbackImaget"
                  type="link"
                  name="userFeedbackImages"
                  // accept="image/*"
                  className="block lg:w-3/12 text-base text-gray-900 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="userFeedbackText"
                    value="Insert Feedback *"
                    className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                  />
                </div>
                <textarea
                  id="userFeedbackText"
                  name="userFeedbackText"
                  rows="10"
                  placeholder="Type your text"
                  className="block w-full text-base text-gray-900 border border-gray-300 rounded-lg p-2 bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                />
              </div>

              <div className="w-full mt-5">
                <Button type="submit">Send Feedback</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
