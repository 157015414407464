import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'flowbite-react'

export default function OrderDetails() {
  return (
    <>
     <div className='space-y-6 mt-8 mx-4'>

     <h1 className='text-2xl font-semibold'>Order List</h1>
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Order No: </b> 1</p>

     <div>
     <Link to="/usersProfile" className=''>
     <Tooltip content="View Profile" trigger="hover" placement='top' className='ml-12'>
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>User Name: </b>
      <span className='text-blue-700'>Alex</span>
      </p> 
      </Tooltip>
      </Link>
     </div>
     
      
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Order Date: </b> 1st January, 2022</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Order Amount: </b> 3000 taka</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Order Validty: </b> 0 Years 4 Months 9 Days</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Package Name: </b> Basic</p>          
     </div> 
    </>
  )
}
