import { useQuery } from "@tanstack/react-query";
import { Button, Label, Modal, TextInput, Tooltip } from "flowbite-react";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import ReadMoreReadLess from "../../../components/readMoreReadLess";
import auth from "../../../firebase.init";
//import pic from "../../../images/bkash.png"

export default function Templete() {
  const [user] = useAuthState(auth);
  // const [url,setUrl]=useState("")

  const templateHandler = (e) => {
    e.preventDefault();
    const templateName = e.target.templateName.value;
    const templateImage = e.target.templateImage.value;
    const templateText = e.target.templateText.value;

    const finalData = {
      templateName,
      templateImage,
      templateText,
      userEmail: user.email,
    };

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/template", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });
    // console.log(orderDetails);
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["userTemplate"],
    queryFn: () =>
      fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/template").then(
        (res) => res.json()
      ),
  });

  const [showModal, setShowModal] = useState(false);

  const onClick = () => {
    setShowModal(true);
  };
  const onClose = () => {
    setShowModal(false);
  };
  // console.log(data, "hghj");

  if (isLoading) return "Loading...";
  return (
    <>
      <div className="templete space-y-6 mt-8 mx-4">
        <h1 className="text-2xl font-semibold">WhatsApp Templetes</h1>
        <div className="flex relative">
          <h1 className="text-lg font-semibold">Approved Templetes</h1>
          <div className="absolute right-10">
            <Tooltip
              content="Add New Templete"
              trigger="hover"
              placement="top"
              className="w-40 text-center"
            >
              <React.Fragment>
                <button onClick={onClick}>
                  <button
                    data-tooltip-target="tooltip-top"
                    data-tooltip-placement="top"
                  >
                    <i className="fa-sharp fa-solid fa-plus text-white bg-[#B22FF0] rounded-[50%] p-2"></i>
                  </button>
                </button>
                <Modal
                  show={showModal}
                  size="md"
                  popup={true}
                  onClose={onClose}
                >
                  <Modal.Header />
                  <Modal.Body>
                    <div className="space-y-3 px-6 pb-4 sm:pb-6 lg:px-2 xl:pb-8">
                      <form onSubmit={templateHandler}>
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                          Save Templete
                        </h3>
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="templeteName"
                              value="Templete Name *"
                            />
                          </div>
                          <TextInput
                            id="templeteName"
                            name="templateName"
                            placeholder="Add Templete "
                            required={true}
                          />
                        </div>
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="templateImage"
                              value="Insert Image"
                              className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                            />
                          </div>
                          <TextInput
                            // onChange={(e)=>{setUrl(e.target.value)}}
                            name="templateImage"
                            id="templateImage"
                            type="text"
                            className="block w-full text-base text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                          />
                        </div>

                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="templeteTextarea"
                              value="Insert Text *"
                              className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                            />
                          </div>
                          <textarea
                            id="templeteTextarea"
                            rows="10"
                            name="templateText"
                            placeholder="Type your text"
                            className="block w-full text-base text-gray-900 border border-gray-300 rounded-lg p-2 bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                          />
                        </div>

                        <div className="w-full">
                          <Button type="submit">Save Message</Button>
                        </div>
                      </form>
                    </div>
                  </Modal.Body>
                </Modal>
              </React.Fragment>
            </Tooltip>
          </div>
        </div>

        <hr />

        {/* <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-5 space-y-6 lg:space-y-0 text-base">
          {data.result.map((item) => (
            <div className="block w-full p-6 bg-white border rounded-lg shadow-md  space-y-6">
              <p className="font-normal text-gray-700 dark:text-gray-400">
                <b>Name : </b> <span className="ml-5">{item.templateName}</span>
              </p>
              
              <img src={item.templateImage} alt="img " className="w-fit" />
              <ReadMoreReadLess limit={200}>
                {item.templateText}
              </ReadMoreReadLess>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
}
