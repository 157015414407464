import React from 'react';
import 'flowbite';
import { Tooltip } from 'flowbite-react';

export default function Always() {
    return (
        <>
            <section className='always mx-8 pt-[130px]' id='contact'>
                <div className='alwaysBody'>
                    <div className='alwaysBodyHead'>
                        <h1 className='sm:text-4xl text-3xl text-center sm:text-left'><b>Always happy <br className='forPC'/> to <span style={{ color: "#B22FF0" }}>HELP!</span></b></h1>
                    </div>
                    <div className='alwaysBodyMain'>
                        <div className='flex flex-col md:flex-row gap-6'>
                            
                            <div className='space-y-[30px]'>
                                <div className="alwaysBodyCard py-6 pl-6 pr-12 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                                    <Tooltip content="Click here to call us" placement="top">
                                        <a href="tel:+880 9611-656145">
                                        <div className='flex'>
                                            <i className="fa-sharp fa-solid fa-phone text-4xl"></i> 
                                            <p className="text-lg font-normal text-gray-700 dark:text-gray-400 ml-5">Call Support</p>
                                        </div>
                                        </a>
                                    </Tooltip>
                                </div>
                                <div className="alwaysBodyCard py-6 pl-6 pr-12 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-pink-700">
                                    
                                <Tooltip content="Chat with us on Satuday-Thurday 9:00-19:00 BST hours" placement="bottom">
                                {/* <a href=""> */}
                                        <div className='flex'>
                                        <i className="fa-sharp fa-solid fa-comment text-4xl"></i> 
                                    <p className="text-lg font-normal text-gray-700 dark:text-gray-400 ml-5">Chat</p>
                                        </div>
                                {/* </a> */}
                                </Tooltip>

                                </div>
                            </div>

                            <div className='space-y-[30px] md:ml-[40px]'>
                                <div className="alwaysBodyCard py-6 pl-6 pr-12 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ">
                                <Tooltip content="Email us on contact@solutya.com" placement="top">
                                <a href="mailto:farhanhasan292@gmail.com">
                                        <div className='flex'>
                                        <i className="fa-sharp fa-solid fa-envelope text-4xl"></i>
                                    <p className="text-lg font-normal text-gray-700 dark:text-gray-400 ml-5">Email</p>
                                            </div>
                                </a>
                                        </Tooltip>
                                </div>
                                <div className="alwaysBodyCard py-6 pl-6 pr-12 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                                <Tooltip content="Click here to see the loaction" placement="bottom">
                                        <div className='cursor-pointer'>
                                            <a href="https://goo.gl/maps/MUENBsJAYXG9w1Fq9" className='flex' target="_blank" rel="noreferrer">
                                            <i className="fa-sharp fa-solid fa-location-dot text-4xl"></i>
                                            <p className="text-lg font-normal text-gray-700 dark:text-gray-400 ml-5">Location</p>
                                            </a>
                                            </div>
                                        </Tooltip>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
