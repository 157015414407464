import React from 'react'

export default function What() {
  return (
    <>
     <div className='what mt-[130px] py-[90px] lg:px-[11rem]'>
        <div className=' flex flex-col md:flex-row mx-8 lg:mx-0 gap-6'>
            <div className=' space-y-6 pr-4 lg:pr-2'>
                <h1 className='text-4xl mb-[90px]'><b>What is Whatsapp Marketing Software?</b></h1>
                <p className='text-[20px] text-[#4E4B66]'>WappSolutya's WhatsApp Marketing Software allows you to send messages to your 1000s of customers.</p>
                <p className='text-[20px] text-[#4E4B66]'>This lead magnet is the best WhatsApp marketing software in India and you can try it out Free Demo today.</p>
            </div>
            <div className='whatSo space-y-6 text-[20px]'>
                <p>WappSolutya software allows you to send messages with:</p>
                <ul className='space-y-6'>
                    <li><span className='text-[#B22FF0] text-[30px] mr-5'><b>1</b></span> Images & Videos</li>
                    <li><span className='text-[#B22FF0] text-[30px] mr-5'><b>2</b></span> PDF & Documents</li>
                    <li><span className='text-[#B22FF0] text-[30px] mr-5'><b>3</b></span> Interactive Action Button</li>
                </ul>
            </div>
        </div>
        </div> 
    </>
  )
}
