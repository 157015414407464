import React from 'react'
import { data } from '../../../utils/demo'

export default function Complete() {
  return (
    <>
      <section className='complete mt-[90px]'>
        <div>
          <img src={data.complete} alt="" />
        </div>
      </section>
      <section className=' mt-[130px]'>
        <div className='flex p-6 justify-center md:items-center flex-col md:flex-row rounded-lg border border-gray-200 shadow-2xl hover:bg-gray-100 '>
          <div className="used-left">
            <div className='used-head'>
            <i className="fa-sharp fa-solid fa-shield-halved"></i>
              <span style={{marginLeft: "10px"}}>Used by 100s of business</span>
            </div>
            <div className='used-sales'>
              <p className='mt-8' style={{fontWeight: "600", fontSize: "24px"}}>Sales & Marketing automation software</p>
              <p className='mt-8'>Connect to your customers across channels like SMS and WhatsApp to simplify your Sales & Marketing needs.</p>
            </div>
          </div>
          <div className='used-right'>
            <ul className='space-y-8'>
              <li><i className="fa-sharp fa-solid fa-cloud-arrow-down mr-3"></i>Uses Cloud API from WhatsApp</li>
              <li><i className="fa-sharp fa-solid fa-message mr-3"></i>1 Million+ Messages Sent</li>
              <li><i className="fa-solid fa-star mr-3"></i>WooCommerce Integration</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}
