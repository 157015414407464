import { Link, useNavigate } from "react-router-dom";
import "./Nav.css";
import logo from "../../../images/logo1.png";
import { FaList, FaSignOutAlt, FaThList, FaUser } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useRef, useState } from "react";
import auth from "../../../firebase.init";
import { signOut } from "firebase/auth";
import { RiArrowDropDownLine } from "react-icons/ri";
export default function Header() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [user] = useAuthState(auth);
  // Navbar toggle code starts
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const navbarContentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        navbarContentRef.current &&
        !navbarContentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Navbar toggle code ends
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.email === "solutyapvtltd@gmail.com") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user]);
  // console.log("ABD ",user)
  const handleSignOut = () => {
    signOut(auth);
    window.localStorage.removeItem("jwtToken");
    navigate("/");
  };
  return (
    <div>
      <section className="flex py-6 md:py-10 items-center lg:justify-evenly justify-between mx-8 ">
        <div className="">
          <Link to="/">
            <img className="w-32 md:w-auto" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="lg:w-2/3 mb-4 flex justify-end items-center ">
          <ul className="hidden md:flex justify-center items-center ">
            <li className="mx-2 lg:mx-4 font-semibold">
              <div className="relative parent flex items-center">
                <span className="pr-2">Products</span> <RiArrowDropDownLine />
              </div>
              <div className="child shadow-md -ml-4 p-4 rounded-md bg-white mx-auto">
                <Link to="/softwareDemo">Demo Project</Link>
              </div>
            </li>
            <li className="mx-2 lg:mx-4 font-semibold">
              <div className="relative parent flex items-center">
                <span className="pr-2"> Services </span> <RiArrowDropDownLine />
              </div>
              <div className="child shadow-md -ml-4 p-4 rounded-md bg-white mx-auto">
                <ul>
                  <li className="my-2">
                    <a href="https://solutya.com/services">
                      Strategy & Research
                    </a>
                  </li>
                  <li className="my-2">
                    <a href="https://solutya.com/services">Web Development</a>
                  </li>
                  <li className="my-2">
                    <a href="https://solutya.com/services">ERP Solution</a>
                  </li>
                  <li className="my-2">
                    <a href="https://solutya.com/services">App Development</a>
                  </li>
                  <li className="my-2">
                    <a href="https://solutya.com/services">SEO & Marketing</a>
                  </li>
                  <li className="my-2">
                    <a href="https://solutya.com/services">24X7 Support</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mx-2 lg:mx-4 font-semibold">
              <Link to="pricing"> Pricing </Link>
            </li>
            <li className="mx-2 lg:mx-4 font-semibold">
              <Link to="reseller"> Reseller </Link>
            </li>
            <li className="mx-2 lg:mx-4 font-semibold">
              <Link to="blog"> Blog </Link>
            </li>
            <li className="mx-2 lg:mx-4 font-semibold">
              <a href="#contact"> Contact</a>
            </li>

            {/* {isAdmin && (
              <li className="mx-2 lg:mx-4 font-semibold">
                <Link to="adminDashboard"> Admin </Link>
              </li>
            )} */}
            {user ? null : (
              <li className=" mx-2 lg:mx-4 ">
                <Link
                  to="login"
                  className="bg-[#b22fef] hover:bg-[#bd54ee] text-white font-bold py-2 px-4 border border-[#b22fef] rounded"
                >
                  {" "}
                  Login{" "}
                </Link>
              </li>
            )}
          </ul>
          <ul className="float-right">
            {user ? (
              <li className="mx-2 lg:mx-4 font-semibold">
                <div className="relative parent flex items-center">
                  <span className="p-2 border-[3px] border-slate-700 rounded-full">
                    <FaUser />
                  </span>
                </div>
                <div className="child shadow-md -ml-24 rounded-md bg-white space-y-3 p-3 z-10">
                  <p className="text-sm leading-6 text-center">
                    {" "}
                    Hello <br />
                    {user?.email}
                  </p>
                  <hr />
                  {user.email === "solutyapvtltd@gmail.com" ? (
                    <p className="mx-2 lg:mx-4 font-semibold pt-3 w-full">
                      <Link
                        to="adminDashboard"
                        className="hover:text-[#b22fef]"
                      >
                        {" "}
                        <FaList className="inline  mr-2" /> Admin Dashboard{" "}
                      </Link>
                    </p>
                  ) : (
                    <p className="mx-2 lg:mx-4 font-semibold pt-3 w-full">
                      <Link to="userDashboard" className="hover:text-[#b22fef]">
                        {" "}
                        <FaList className="inline  mr-2" /> User Dashboard{" "}
                      </Link>
                    </p>
                  )}
                  <p className=" mx-2 lg:mx-4 font-semibold flex items-center hover:text-[#b22fef]">
                    <button onClick={handleSignOut}>
                      {" "}
                      <FaSignOutAlt className="inline  mr-2 text-red-800 " />{" "}
                      Logout{" "}
                    </button>
                  </p>
                </div>
              </li>
            ) : (
              <p></p>
            )}
          </ul>
          <div className="mobile-dropdown">
            <div className="md:hidden">
              <button ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
                <FaThList
                  className={`${isOpen ? "text-fuchsia-600" : ""} text-xl`}
                />
              </button>
            </div>
            <div
              className={`${
                isOpen
                  ? "absolute w-full left-0 bg-white pt-4 mt-5 transition duration-500 translate-y-0 z-50"
                  : "absolute w-full left-0 bg-gray-50 pt-4 mt-5 transition duration-300 -translate-y-20 -z-20 opacity-0 md:hidden "
              } 
            `}
            >
              <ul ref={navbarContentRef} className="w-full text-center shadow-md">
                <li className="mr-2 my-2 py-1 border-b-2">
                  <Link to="/softwareDemo"> Product </Link>
                </li>
                <li className=" my-2 py-1 border-b-2">
                  <a
                    href="https://solutya.com/services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Service
                  </a>
                </li>
                <li className=" my-2 py-1 border-b-2">
                  <Link to="pricing"> Pricing </Link>
                </li>
                <li className=" my-2 py-1 border-b-2">
                  <Link to="reseller"> Reseller </Link>
                </li>
                <li className=" my-2 py-1 border-b-2">
                  <Link to="blog"> Blog </Link>
                </li>
                <li className=" my-2 py-1 border-b-2">
                  <a href="#contact"> Contact </a>
                </li>
                {/* <li className=" my-2 py-1 border-b-2">
                  <Link to="userDashboard"> User </Link>
                </li>
                {isAdmin && (
                  <li className=" my-2 py-1 border-b-2">
                    <Link to="adminDashboard"> Admin </Link>
                  </li>
                )} */}
                {user ? (
                  <li
                    className=" my-2 py-1 border-b-2 pb-4"
                    onClick={handleSignOut}
                  >
                    <FaSignOutAlt className="inline  mr-2 text-red-800 " />
                    Logout
                  </li>
                ) : (
                  <li className=" my-2 py-1 border-b-2 pb-4">
                    <Link
                      to="login"
                      className="bg-[#b22fef] hover:bg-[#bd54ee] text-white font-bold py-2 px-4 border border-[#b22fef] rounded"
                    >
                      {" "}
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
