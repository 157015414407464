import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../../firebase.init";

export default function Login() {
  const navigation = useNavigate();
  const [signInWithEmailAndPassword, user, loading] =
    useSignInWithEmailAndPassword(auth);
  const [showPassword, setShowPassword] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    // console.log(email, password);

    signInWithEmailAndPassword(email, password);

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((result) => {
        window.localStorage.setItem("jwtToken", result.token);
      });
      
  };

  useEffect(() => {
    if (user) {
      // fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/login", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ email: user?.email }),
      // })
      //   .then((res) => res.json())
      //   .then((tokenData) => console.log(tokenData, "tokenData"));
      // console.log(user.user.email)
      user.user.email === "solutyapvtltd@gmail.com"
      ? navigation("/adminDashboard")
      : navigation("/userDashboard");
    }
  }, [user, navigation]);
  if (loading) return <p>Loading...</p>;
  if (user) return navigation("/");

  return (
    <div className="h-screen flex justify-center items-center ">
      <form onSubmit={handleSubmit} className="md:w-2/5 mt-[-100px]" action="">
        <p className="flex justify-center font-bold text-2xl">
          Sign in to our platform
        </p>
        <div className="space-y-6  py-4 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email" value="Your email" />
            </div>

            <TextInput
              name="email"
              id="email"
              placeholder="Enter your email"
              required={true}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="password" value="Your password" />
            </div>
            <div className="relative">
              <TextInput
                name="password"
                id="password"
                placeholder="Enter your password"
                type={`${showPassword ? "text" : "password"}`}
                required={true}
              />
              <p
                className="absolute right-3 top-2 z-10 opacity-50 "
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <Checkbox id="remember" />
              <Label htmlFor="remember">Remember me</Label>
            </div>

            <button className="text-sm text-[#c758fa] hover:underline dark:text-[#c758fa]">
              Lost Password?
            </button>
          </div>
          <div className="">
            <Button
              type="submit"
              className="w-full bg-[#b22fef] hover:bg-[#c758fa]"
            >
              Log in
            </Button>
          </div>
          <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
            Not registered?
            <Link to="/register">
              <button className="text-[#c758fa] ml-2 hover:underline dark:text-[#c758fa]">
                Create account
              </button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}
