import React from "react";
import emailjs from "@emailjs/browser";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";
import { Link } from "react-router-dom";
const ProcessingOrderList = ({ props }) => {
  // console.log(props);
  const [user] = useAuthState(auth);

  const sendMail = async (id, targetEmail, amount, reply, plan) => {
    // console.log(reply, amount,plan);
    if (plan === "Basic" || plan === "Professional") {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 365);
    } else {
      var currentDate = "Lifetime";
    }
    const templateParams = {
      targetEmail,
      amount,
      plan,
      targetDate: currentDate.toString()?.slice(0, 16),
      reply,
    };
    await emailjs
      .send(
        "service_bifdz8e",
        "template_cc6zep8",
        templateParams,
        // "zZFqCHlUyaRRvKx-1"
        "zZFqCHlUyaRRvKx-1"
      )
      .then(
        (result) => {
          // console.log(result.text);
          // console.log(result);
          if (result.status === 200) {
            fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/orders", {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ id: id, status: "Succeed" }),
            })
              .then((res) => res.json())
              .then((data) => {
                data.code === 200 && props.refetch();
                console.log(data);
              });
            // alert("Mail Send Successfully")
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const checkDate = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 365);
    console.log(currentDate);
    // console.log(new Date() + 365);
  };

  const handleDelete = (id) => {
    fetch(`https://busy-cyan-fox-suit.cyclic.app/api/v1/orders`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        data.code === 200 && props.refetch();
        // console.log(data);
      });
  };

  return (
    <div className="p-5 mt-4">
      <h1
        onClick={checkDate}
        className="text-2xl font-semibold flex justify-center"
      >
        Process Order List
      </h1>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6 text-center">
                SL
              </th>
              {/* <th scope="col" className="py-3 px-6">
                User Mail
              </th> */}
              <th scope="col" className="py-3 px-6 text-center">
                Plan Type
              </th>
              {/* <th scope="col" className="py-3 px-6">
                Purchased Email
              </th> */}
              <th scope="col" className="py-3 px-6 text-center">
                Status
              </th>
              {/* <th scope="col" className="py-3 px-6">
                TAXID
              </th> */}
              {/* <th scope="col" className="py-3 px-6">
                Number / Account Number
              </th> */}
              <th scope="col" className="py-3 px-6">
                Method
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Purchase Date
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Amount
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {props?.ProcessingOrders?.map((item, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="py-4 px-6  text-center">{index + 1}</td>
                {/* <td className="py-4 px-6">{item.userEmail}</td> */}
                <td className="py-4 px-6 text-center">{item.planName}</td>
                {/* <td className="py-4 px-6 ">{item.email}</td> */}
                <td className="py-4 px-6 text-[#E7500A] text-center font-bold">
                  {item.status}
                </td>
                {/* <td className="py-4 px-6 ">
                  {item.txid || item.bankTransactionId}
                </td> */}
                {/* <td className="py-4 px-6 ">
                  {item.phoneNo || item.bankTransactionId}
                </td> */}
                <td className="py-4 px-6 ">{item.method}</td>
                <td className="py-4 px-6 text-center">
                  {item.createdAt?.slice(0, 10)}
                </td>
                <td className="py-4 px-6 text-center"> {item.amount} </td>
                <td className="py-4 px-6 text-center flex">
                  <button
                    onClick={() =>
                      sendMail(
                        item._id,
                        item.email,
                        item.amount,
                        item.email,
                        item.planName
                      )
                    }
                    className="font-medium border-2 bg-green-400 px-2 text-white mx-2"
                  >
                    Send Mail
                  </button>
                  <button
                    onClick={() => handleDelete(item._id)}
                    className="font-medium border-2 bg-red-400 text-white px-2 mx-2"
                  >
                    Delete
                  </button>
                  <Link to={`/processingOrderListDetails/${item._id}`}>
                    <button className="font-medium border-2 bg-yellow-400 text-white px-2 mx-2">
                      View
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ProcessingOrderList;
