import React from 'react'

export default function Experience() {
  return (
    <>
    <section className='experience mt-[130px]'>
    <h1 className='text-4xl text-center'><b>Experience An Online Demo <br/> Like Never Before!</b></h1>
    <div className="experience-body mx-auto mt-[90px] mb-20 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
    <div className='experience-radio space-y-8'>
        <div className="flex items-center">
            <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-4 h-4"/>
            <label htmlFor="default-radio-1" className="ml-5 font-medium text-gray-900 dark:text-gray-300">WhatsApp Text Message</label>
        </div>
        <div className="flex items-center">
            <input id="default-radio-2" type="radio" value="" name="default-radio" className="w-4 h-4"/>
            <label htmlFor="default-radio-2" className="ml-5 font-medium text-gray-900 dark:text-gray-300">WhatsApp Image and Button Message</label>
        </div>
        <div className="flex items-center">
            <input id="default-radio-3" type="radio" value="" name="default-radio" className="w-4 h-4"/>
            <label htmlFor="default-radio-3" className="ml-5 font-medium text-gray-900 dark:text-gray-300">WhatsApp Promotional Message</label>
        </div>
    </div>
    <div className='experience-number text-center px-8 py-3'>
        <p className=''>Just enter the valid mobile number and select the correct country code to get your demo started.</p>
        <input type="number" id='phoneNumber' className='mt-5 w-10/12 h-12 px-4 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm'/>
        <button className='homeButton homeButton1 block mx-auto my-4'>Send</button>
    </div>
    </div>
    </section>
    </>
  )
}
