import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import ReadMoreReadLess from "../../../components/readMoreReadLess";

export default function Blog() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["blog"],
    queryFn: () =>
      fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/blog").then((res) =>
        res.json()
      ),
  });

  if (isLoading) return "loading...";

  return (
    <>
      <section className="blog space-y-7 pt-[130px]" id="blog">
        <h1 className="text-4xl text-center mb-[90px]">
          <b>Blog</b>
        </h1>

        <div className="flex flex-col lg:flex-row lg:space-x-[40px] space-y-8 lg:space-y-0 justify-center ">
          {data.result?.slice(0, 3).map((item, index) => (
            <div key={index} className="block max-w-sm p-6 mx-auto lg:mx-0 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {item.blogHeadline} #{index + 1}
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                <ReadMoreReadLess limit={270}>{item.blogText}</ReadMoreReadLess>
                {/* <p className='text-blue-500 mt-2'><Link to="/blog" >{item.templateText}</Link></p>    */}
              </p>
            </div>
          ))}
        </div>

        <div className="">
          <button className="homeButton homeButton1 block mx-auto my-4">
            <Link to="/blog">See More Blogs</Link>
          </button>
        </div>
      </section>
    </>
  );
}
