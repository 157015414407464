import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useState } from 'react';

function CountryCode() {
  const [value, setValue] = useState()
  return (
    <PhoneInput
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
  )
} 

export default CountryCode;

// import React from 'react';
//   import ReactDOM from 'react-dom';
//   import IntlTelInput from 'react-intl-tel-input';
//   import 'react-intl-tel-input/dist/main.css';

//   ReactDOM.render(
//     <IntlTelInput
//       preferredCountries={['tw']}
//       onPhoneNumberChange={onChange()}
//       onPhoneNumberBlur={onBlur()}
//     />,
//     document.getElementById('root'),
// );