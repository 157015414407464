import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import ReadMoreReadLess from "../../../components/readMoreReadLess";

export default function BlogPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading, error } = useQuery({
    queryKey: ["blog"],
    queryFn: () =>
      fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/blog").then((res) =>
        res.json()
      ),
  });

  if (isLoading) return "loading...";

  return (
    <>
      <div className="my-16  mx-8 lg:mx-44">
        <h1 className="text-4xl text-center mb-[90px]">
          Blogs
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-[40px] space-y-10 lg:space-y-0 text-base justify-center">
          {data.result.map((item, index) => (
            <div key={index} className="block max-w-sm p-6 mx-auto lg:mx-0 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 w-full">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {item.blogHeadline} #{index + 1}
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                <ReadMoreReadLess limit={270}>{item.blogText}</ReadMoreReadLess>
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
