import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import auth from "../../../firebase.init";

const Order = () => {
  const [user, loading] = useAuthState(auth);
  const [order, setOrder] = useState([]);
  useEffect(() => {
    fetch(
      `https://busy-cyan-fox-suit.cyclic.app/api/v1/orders?email=${user?.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setOrder(data.result);
      });
  }, [user?.email]);
  if (loading) return <p>Loading...</p>;

  return (
    <div className="p-5 mt-4">
      <h1 className="text-2xl font-semibold flex justify-center">My Orders</h1>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6 text-center">
                SL
              </th>
              <th scope="col" className="py-3 px-6 ">
                Plan Type
              </th>
              <th scope="col" className="py-3 px-6 ">
                Email
              </th>
              <th scope="col" className="py-3 px-6 ">
                Purchase Date
              </th>
              <th scope="col" className="py-3 px-6 ">
                Amount
              </th>
              <th scope="col" className="py-3 px-6">
                Action
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {order?.map((item, index) => (
              <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="py-4 px-6  text-center">1</td>
                <td className="py-4 px-6 ">{item.planName}</td>
                <td className="py-4 px-6 ">{item.email}</td>
                <td className="py-4 px-6">{item.createdAt?.slice(0, 10)}</td>
                <td className="py-4 px-6 ">{item.amount}</td>
                {/* <td className="py-4 px-6 text-[#E7500A]  font-bold">
                  {item.status === "Processing"
                    ? setColor("red")
                    : setColor("green")}
                </td> */}

                {item.status === "Processing" ? (
                  <td className="py-4 px-6 text-[#E7500A]  font-bold">
                    {item.status}
                  </td>
                ) : (
                  <td className="py-4 px-6 text-[#00B050]  font-bold">
                    {item.status}
                  </td>
                )}

                <td className="py-4 px-6 text-center">
                  <Link to={`/orderDetail/${item._id}`}>
                    <button className="font-medium text-blue-600 dark:text-blue-500 ">
                      View
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Order;
