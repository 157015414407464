import React, { useRef } from "react";
import Slider from "react-slick";
// import { data } from "../../utils/demo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowCircleLeft, FaArrowCircleRight, FaCircleNotch } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
export default function Client() {
  const {
    data: adminFeedback,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["adminFeedbacks"],
    queryFn: () =>
      fetch(`https://busy-cyan-fox-suit.cyclic.app/api/v1/admin-feedback`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
        },
      }).then((res) => res.json()),
  });
  // console.log(adminFeedback);
  // const clientDetails = [
  //   {
  //     name: "Alex",
  //     feedback:
  //       "“Creating a product from scratch is a huge investment, but WappSolutya changed this game for me. I am into the IT business, and a massive part of my business revolves around automation. I came across WappSolutya's reseller program through a friend, and things have been much easier ever since. Highly recommended! Go for it without giving it a second thought!”",
  //     image: data.person1,
  //     profession: "IT Business Owner",
  //     fb: "https://www.facebook.com/Solutya",
  //     ig: "https://www.instagram.com/solutya/",
  //     li: "https://www.linkedin.com/company/solutya/mycompany/",
  //   },
  //   {
  //     name: "Jennifer",
  //     feedback:
  //       "“Creating a product from scratch is a huge investment, but WappSolutya changed this game for me. I am into the IT business, and a massive part of my business revolves around automation. I came across WappSolutya's reseller program through a friend, and things have been much easier ever since. Highly recommended! Go for it without giving it a second thought!”",
  //     image: data.person2,
  //     profession: "IT Business Owner",
  //     fb: "https://www.facebook.com/Solutya",
  //     ig: "https://www.instagram.com/solutya/",
  //     li: "https://www.linkedin.com/company/solutya/mycompany/",
  //   },
  //   {
  //     name: "Stephanie",
  //     feedback:
  //       "“Creating a product from scratch is a huge investment, but WappSolutya changed this game for me. I am into the IT business, and a massive part of my business revolves around automation. I came across WappSolutya's reseller program through a friend, and things have been much easier ever since. Highly recommended! Go for it without giving it a second thought!”",
  //     image: data.person3,
  //     profession: "IT Business Owner",
  //     fb: "https://www.facebook.com/Solutya",
  //     ig: "https://www.instagram.com/solutya/",
  //     li: "https://www.linkedin.com/company/solutya/mycompany/",
  //   },
  //   {
  //     name: "Max",
  //     feedback:
  //       "“Creating a product from scratch is a huge investment, but WappSolutya changed this game for me. I am into the IT business, and a massive part of my business revolves around automation. I came across WappSolutya's reseller program through a friend, and things have been much easier ever since. Highly recommended! Go for it without giving it a second thought!”",
  //     image: data.person4,
  //     profession: "IT Business Owner",
  //     fb: "https://www.facebook.com/Solutya",
  //     ig: "https://www.instagram.com/solutya/",
  //     li: "https://www.linkedin.com/company/solutya/mycompany/",
  //   },
  //   {
  //     name: "Gloria",
  //     feedback:
  //       "“Creating a product from scratch is a huge investment, but WappSolutya changed this game for me. I am into the IT business, and a massive part of my business revolves around automation. I came across WappSolutya's reseller program through a friend, and things have been much easier ever since. Highly recommended! Go for it without giving it a second thought!”",
  //     image: data.person5,
  //     profession: "IT Business Owner",
  //     fb: "https://www.facebook.com/Solutya",
  //     ig: "https://www.instagram.com/solutya/",
  //     li: "https://www.linkedin.com/company/solutya/mycompany/",
  //   },
  //   {
  //     name: "Jamie",
  //     feedback:
  //       "“Creating a product from scratch is a huge investment, but WappSolutya changed this game for me. I am into the IT business, and a massive part of my business revolves around automation. I came across WappSolutya's reseller program through a friend, and things have been much easier ever since. Highly recommended! Go for it without giving it a second thought!”",
  //     image: data.person6,
  //     profession: "IT Business Owner",
  //     fb: "https://www.facebook.com/Solutya",
  //     ig: "https://www.instagram.com/solutya/",
  //     li: "https://www.linkedin.com/company/solutya/mycompany/",
  //   },
  // ];

  // const count = 0;
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="clients space-y-[90px]">
        <h1 className="text-4xl font-bold text-center md:mt-[130px]">
          Our Clients
        </h1>
        <div className="px-[4%]">
         
         {
          isLoading?    <FaCircleNotch className="animate-spin text-center text-3xl my-10 w-full text-[#b22fef]" /> :
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
            className="lg:mx-24"
          >
            {adminFeedback?.result.map((data, index) => (
              <div key={index} className="p-4  ">
                <div className="text-left block max-w-sm  p-6 space-y-5  border border-gray-200 rounded-lg shadow-md hover:border-1  hover:shadow-lg hover:shadow-fuchsia-400  mx-auto bg-white h-[100%] ">
                  <p className="font-normal text-center">{`${data?.feedbackText}`}</p>
                  <img
                    src={`${data?.feedbackImage}`}
                    className="rounded-[50%] w-[6rem] h-[6rem] object-cover m-auto"
                    alt=""
                  />
                  <div className="text-center">
                    <p>{`${data?.clientName}`}</p>
                    <p>{`${data?.designation}`}</p>
                    {/* <div className="space-x-4 mt-3">
                    <a
                      href={`${data?.fb}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook text-xl"></i>
                    </a>
                    <a
                      href={`${data?.ig}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram text-xl"></i>
                    </a>
                    <a
                      href={`${data?.li}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-linkedin text-xl"></i>
                    </a>
                  </div> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
         }
          <div className="text-center text-4xl text-fuchsia-600 pt-4 space-x-4">
            <button className="button" onClick={previous}>
              <FaArrowCircleLeft />
            </button>
            <button className="button" onClick={next}>
              <FaArrowCircleRight />
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
