import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { data } from '../../../utils/demo'
import { FaArrowCircleRight } from 'react-icons/fa'

export default function Home() {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <>
     <section className='home'>
        {/* <img className='offerImg' src={data.offer} alt=""/> */}
        <div className='flex flex-col md:flex-row px-[5%] mt-[45px] lg:mt-[30px] mb-[45px] lg:mb-[90px] items-center '>
            <div>
                <small className='my-6'><i>Powered by Whatsapp API</i></small>
                <h1 className='text-4xl my-6'><b>We help businesses conncet with thier customers</b></h1>
                <p className='text-2xl my-10 tracking-wide'>WappSolutya helps business conncet with thier customers on <span style={{color: "#25D366"}}><b>Whatsapp</b></span> & <span style={{color: "#0CA3D1"}}><b>WordPress</b></span></p>
                <ul className='space-y-2'>
                  <li><i className="fa-sharp fa-solid fa-circle-check mx-4"></i>No setup or monthly fee</li>
                  <li><i className="fa-sharp fa-solid fa-circle-check mx-4"></i>Get green tick verification and send bulk campaigns</li>
                  <li><i className="fa-sharp fa-solid fa-circle-check mx-4"></i>Whitelabel for reselling</li>
                </ul>
                <div className='flex mt-10 mb-5'>
                  <Link to="/pricing" ><button className='homeButton homeButton1'>View Pricing <FaArrowCircleRight className='inline  ml-1 text-center text-lg'/></button></Link> 
                  <a href="#demo"><button className='homeButton homeButton2'>Try Demo</button></a>
                </div>
            </div>
            <div className=''>
                <img src={data.canvas} alt="canvas" className='w-80 md:w-[800px] '/>
            </div>
        </div>

    </section> 
    </>
  )
}
