import { useQuery } from "@tanstack/react-query";
import { Button, Label, TextInput } from "flowbite-react";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import auth from "../../../firebase.init";

export default function AdminBlog() {
  const [user] = useAuthState(auth);

  const blogHandler = (e) => {
    e.preventDefault();
    const blogHeadline = e.target.blogHeadline.value;
    const blogImage = e.target.blogImage.value;
    const blogText = e.target.blogText.value;

    const finalData = {
      blogHeadline,
      blogImage,
      blogText,
      userEmail: user.email,
    };

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/blog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });
    // console.log(orderDetails);
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["blog"],
    queryFn: () =>
      fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/blog").then((res) =>
        res.json()
      ),
  });

  const handleDelete = (id) => {
    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/blog", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        refetch();
      });
  };

  if (isLoading) return "loading...";
  return (
    <>
      <div className="space-y-6 my-8 mx-4">
        <h1 className="text-2xl font-semibold">Blog</h1>

        <section>
          <h1 className="text-xl font-semibold">Create New Blog</h1>
          <hr className="my-3" />

          <div className="flex w-full items-center justify-center">
            <div className="max-w-[950px] w-full ">
              <form onSubmit={blogHandler} className="space-y-5">
                <div>
                  <label
                    htmlFor="blogHeadlinet"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Blog Headline
                  </label>
                  <input
                    name="blogHeadline"
                    type="text"
                    id="blogHeadline"
                    className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="blogImage"
                      value="Insert Image"
                      className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                    />
                  </div>
                  <TextInput
                    name="blogImage"
                    id="blogImage"
                    type="text"
                    accept="image/*"
                    className="block w-full text-base text-gray-900 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  />
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="blogText"
                      value="Insert Text *"
                      className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                    />
                  </div>
                  <textarea
                    name="blogText"
                    id="blogText"
                    rows="10"
                    placeholder="Type your text"
                    className="block w-full text-base text-gray-900 border border-gray-300 rounded-lg p-2 bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  />
                </div>

                <div className="w-full">
                  <Button type="submit">Upload Blog</Button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section>
          <h1 className="text-xl font-semibold">Your Blogs</h1>
          <hr className="my-3" />

          <div>
            <table className="border-collapse border border-slate-500 lg:w-9/12 mt-8 text-center mx-auto">
              <thead>
                <tr className="h-9">
                  <th className="border border-slate-600 w-2/12">Blog No.</th>
                  <th className="border border-slate-600">Blog Name</th>
                  <th className="border border-slate-600 w-2/12">Blog View</th>
                  <th className="border border-slate-600 w-2/12">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.result.map((item, index) => (
                  <tr className="h-9">
                    <td className="border border-slate-700 ">{index + 1}</td>
                    <td className="border border-slate-700 ">
                      {item.blogHeadline}
                    </td>
                    <td className="border border-slate-700 ">
                      <Link
                        to={`/adminBlogView/${item._id}`}
                        className="text-blue-800 underline"
                      >
                        View
                      </Link>
                    </td>
                    <td className="border border-slate-700 lg:space-x-5">
                      <button
                        onClick={() => handleDelete(item._id)}
                        type="button"
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-2 my-1 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      >
                        <i className="fa-sharp fa-solid fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  );
}
