import { useState } from "react";

const ReadMoreReadLess= ({limit ,  children}) => {

    const[isReadMoreShown, setReadMoreShown] = useState(false); 

    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState) 
    }

    return(
        <>
            {isReadMoreShown ? children : children.substr(0 , limit)} <span className="cursor-pointer text-blue-500" onClick={toggleBtn}> {isReadMoreShown ? 'Read Less' : '...Read More'} </span>
        </>
    )
}

export default ReadMoreReadLess;
