import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';

export default function UsersList() {
   
    const [user] = useAuthState(auth);
    const [users, setUsers] = useState([])

    // console.log(users)
    // console.log(user)
    // User fetching
    useEffect(() => {
      fetch(
        `https://busy-cyan-fox-suit.cyclic.app/api/v1/users`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("jwtToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUsers(data.result);
          console.log(data.result)
        });
    }, [user?.email]);

  return (
    <div className='p-5 mt-4'>
    <h1 className='text-2xl font-semibold flex justify-center'>User List</h1>
<div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-5">

  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 z-0">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
          <th scope="col" className="py-3 px-6 text-center">
            SL
              </th>
              <th scope="col" className="py-3 px-6 text-center">
              Name
              </th>
              <th scope="col" className="py-3 px-6 text-center">
              Username
              </th>
              <th scope="col" className="py-3 px-6 text-center">
              Address
              </th>
              <th scope="col" className="py-3 px-6 text-center">
              Mobile No
              </th>
              <th scope="col" className="py-3 px-6 text-center">
              Email
              </th>
              <th scope="col" className="py-3 px-6 text-center" >
                Action
              </th>
          </tr>
      </thead>
      <tbody>
{
  users.map((user,index)=>
  (
    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
           
    <td className="py-4 px-6  text-center">
          {index+1}
        </td>
        <td className="py-4 px-6 text-center">
        {user.name}
        </td>
        <td className="py-4 px-6  text-center">
        {user.username}
        </td>
        <td className="py-4 px-6 text-center">
        {user.name}
        </td>
        <td className="py-4 px-6 text-center">
        {user.number}
        </td>
        <td className="py-4 px-6 text-center">
        {user.email}
        </td>
        <td className="py-4 px-6 text-center">
            <button className="font-medium text-red-600 dark:text-red-500 ">Delete</button>
        </td>
    </tr>
  ))
}
        
             

      </tbody>
  </table>
</div>

  </div>
  )
}
