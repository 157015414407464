import { Modal } from "flowbite-react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useParams, useSearchParams } from "react-router-dom";
import auth from "../../firebase.init";
import { data } from "../../utils/demo";
import { FaArrowDown } from "react-icons/fa";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

export default function Payments() {
  const { planName } = useParams();
  const formRef = useRef(null);
  const [user] = useAuthState(auth);
  const [searchParams, setSearchParams] = useSearchParams();

  //   console.log(planName.split("&")[1].slice(3, 4));
  //   console.log(planName.split("&")[1].slice(3, 4));
  // const [showModalQr, setShowModalQr] = useState(false);
  const [bkashSign, setbkashSign] = useState(false);
  const [nagadSign, setNagadSign] = useState(false);
  const [bank, setBank] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPC, setTotalPC] = useState(0);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  // const onClickQr = () => {
  //   setShowModalQr(true);
  // };

  // const onCloseQr = () => {
  //   setShowModalQr(false);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (planName === "Basic") {
      setTotalAmount(1499);
      setTotalPC(1);
    } else if (planName === "Standard") {
      setTotalAmount(2999);
      setTotalPC(1);
    } else if (planName === "Professional") {
      setTotalAmount(5999);
      setTotalPC(5);
    } else if (planName === "reseller-premium") {
      setTotalAmount(44999);
      setTotalPC("Lifetime");
    } else if (planName === "reseller-standard") {
      setTotalAmount(24999);
      setTotalPC("Lifetime");
    } else if (planName === "reseller-basic") {
      setTotalAmount(14999);
      setTotalPC("Lifetime");
    }
  }, [planName]);

  const orderHandler = (e) => {
    e.preventDefault();
    // console.log(e.target.email.value);
    const country = e.target.countries.value;
    const currency = e.target.currency.value;
    const email = e.target.email.value;
    const phoneNo = e.target.phoneNo.value;
    const billAddress = e.target.billAddress.value;
    const billCity = e.target.billCity.value;
    const billState = e.target.billState.value;
    const billZip = e.target.billZip.value;
    const amount = parseInt(totalAmount);
    const pc = parseInt(totalPC);
    const finalData = {
      country,
      currency,
      email,
      phoneNo,
      billAddress,
      billCity,
      billState,
      billZip,
      amount,
      pc,
      planName,
      userEmail: user.email,
    };
    if (nagadSign) {
      const number = e.target.nagadNo.value;
      const txid = e.target.nagadTransactionId.value;
      var orderDetails = { ...finalData, number, txid, method: "nagad" };
    } else if (bkashSign) {
      const number = e.target.bkashNo.value;
      const txid = e.target.bKshTransactionId.value;
      var orderDetails = { ...finalData, number, txid, method: "bkash" };
    } else if (bank) {
      const bankName = e.target.bankName.value;
      const accountNumber = e.target.bankAccount.value;
      const bankTransactionId = e.target.bankTransactionId.value;
      var orderDetails = {
        ...finalData,
        bankName,
        accountNumber,
        bankTransactionId,
        method: "bank",
      };
    }

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderDetails),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });

    // console.log(orderDetails);
    formRef.current.reset();
  };

  //   useEffect(() => {
  //     if (planName === "Basic") {
  //       if (planName.split("&")[1].slice(3, 4) === "$") {
  //         setTotalAmount(14.69);
  //         setTotalPC(1);
  //       } else if (planName.split("&")[1].slice(3, 4) === "৳") {
  //         setTotalAmount(1499);
  //         setTotalPC(1);
  //       }
  //     } else if (planName === "Standard") {
  //       if (planName.split("&")[1].slice(3, 4) === "$") {
  //         setTotalAmount(29.39);
  //         setTotalPC(1);
  //       } else {
  //         setTotalAmount(2999);
  //         setTotalPC(1);
  //       }
  //     } else if (planName === "Professional") {
  //       if (planName.split("&")[1].slice(3, 4) === "$") {
  //         setTotalAmount(58.79);
  //         setTotalPC(1);
  //       } else {
  //         setTotalAmount(4999);
  //         setTotalPC(1);
  //       }
  //     }
  //   }, [planName]);

  return (
    <>
      <div>
        <form onSubmit={orderHandler} ref={formRef}>
          <div className="flex  w-[100%] px-8 lg:px-20 gap-10 py-10 mt-5 flex-col md:flex-col lg:flex-row">
            <div className="w-[100%] lg:pl-8 ">
              <div className="space-y-2 ">
                <h1 className="text-3xl">
                  <b>Buy Wapp-Solutya Marketing Software Now!</b>
                </h1>
                <p className="my-2">
                  You'll be redirected to a safe Payment Gateway.
                </p>
                <p className="text-[#FF5733] mt-2">
                  * To Buy this Package,You must have an Account.*
                </p>
              </div>

              <div className="mt-5">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  Country <span className="text-[#F33112]"> *</span>
                </label>
                <select
                  name="countries"
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                >
                  <option value="BD" selected>
                    Bangladesh
                  </option>
                  <option value="US">United States</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="currency"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  Currency <span className="text-[#F33112]">*</span>
                </label>
                <select
                  name="currency"
                  id="currency"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                >
                  <option value="BDT" selected>
                    Bangladeshi Taka- BDT
                  </option>
                  <option value="USD">United States Dollar- USD</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="phoneNo"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  Phone with Country Code{" "}
                  <span className="text-[#F33112]">*</span>
                </label>
                <input
                  name="phoneNo"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phoneNo"
                  type="text"
                  placeholder="Phone No"
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  Email
                  <span className="text-[#F33112]">*</span>
                </label>
                <input
                  name="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email"
                />
              </div>

              <div>
                <label
                  htmlFor="address"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  Billing Address <span className="text-[#F33112]"> *</span>
                </label>
                <textarea
                  name="billAddress"
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                ></textarea>
              </div>

              <div>
                <label
                  htmlFor="city"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  City <span className="text-[#F33112]">*</span>
                </label>
                <input
                  name="billCity"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  type="text"
                  placeholder="city"
                />
              </div>

              <div>
                <label
                  htmlFor="state "
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  State <span className="text-[#F33112]">*</span>
                </label>
                <input
                  name="billState"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  type="text"
                  placeholder="State"
                />
              </div>

              <div>
                <label
                  htmlFor="zipCode"
                  className="block mb-2 text-md font-normal text-gray-900 "
                >
                  Zip Code / Pin Code <span className="text-[#F33112]">* </span>
                </label>
                <input
                  name="billZip"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  type="text"
                  placeholder="Zip Code"
                />
              </div>
            </div>

            <div className="space-y-5 w-[100%] lg:pr-8 ">
              <div className="bg-[#F5EEEE] p-5  rounded-lg">
                <h2 className="font-bold flex justify-center py-3">
                  YOUR ORDER
                </h2>
                <div className="shadow-sm bg-[#FFFFFF] shadow-[#B22FF0] p-3 ]">
                  <div className="flex justify-between gap-10 p-2">
                    <div className="font-bold">Product</div>
                    <div className="font-bold">Subtotal</div>
                  </div>
                  <hr />
                  <div className="flex justify-between gap-10 p-2 py-3">
                    <div className="text-[#777877]">
                      {planName}
                      <p className="text-[13px] text-[#F33112] py-[2px]">
                        For {totalPC} PC
                      </p>
                    </div>
                    <div className="text-[#777877]">{totalAmount} BDT</div>
                  </div>

                  <hr />
                  <div className="flex justify-between gap-10 p-2 py-3">
                    <div>Subtotal</div>
                    <div className="text-[#777877]">
                      {totalAmount} BDT
                      <i className="fa-sharp fa-solid fa-bangladeshi-taka-sign"></i>
                    </div>
                  </div>
                  {planName === "reseller-premium" ||
                  planName === "reseller-standard" ||
                  planName === "reseller-basic" ? (
                    <>
                      <hr />
                      <div className="flex justify-between gap-10 p-2 py-3">
                        <div className="text-red-600">Vat & Tax (12.5%)</div>
                        <div className="text-[#777877]">
                          {parseInt(totalAmount * 0.125)} BDT
                          <i className="fa-sharp fa-solid fa-bangladeshi-taka-sign"></i>
                        </div>
                      </div>

                      <hr />
                      <div className="flex justify-between gap-10 p-2 py-3">
                        <div className="font-bold">Total</div>
                        <div className="text-[#777877]">
                          {parseInt(totalAmount + totalAmount * 0.125)} BDT
                          <i className="fa-sharp fa-solid fa-bangladeshi-taka-sign"></i>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <hr />
                      <div className="flex justify-between gap-10 p-2 py-3">
                        <div className="font-bold">Total</div>
                        <div className="text-[#777877]">
                          {totalAmount} BDT
                          <i className="fa-sharp fa-solid fa-bangladeshi-taka-sign"></i>
                        </div>
                      </div>
                    </>
                  )}

                  {/* <hr />
                  <div className="flex justify-between gap-10 p-2 py-3">
                    <div className="font-bold">Total</div>
                    <div className="text-[#777877]">
                      {totalAmount} BDT
                      <i className="fa-sharp fa-solid fa-bangladeshi-taka-sign"></i>
                    </div>
                  </div> */}
                </div>
                <div className=" p-2 py-5">
                  <div className="flex items-center mb-4">
                    <input
                      onClick={() => {
                        setbkashSign(true);
                        setBank(false);
                        setNagadSign(false);
                      }}
                      id="country-option-1"
                      type="radio"
                      name="countries"
                      value="Bkash"
                      className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300  focus:outline-none"
                    />
                    <label
                      for="country-option-1"
                      className="block ml-2 text-sm font-medium text-gray-900 "
                    >
                      bkash
                    </label>
                  </div>
                  {bkashSign ? (
                    <div className="bg-[#FFFFFF] p-3 my-5 text-[#777877] py-5">
                      <p className="text-[15px]">
                        Please complete your bKash payment at first, then fill
                        up the form below. bKash Personal Number :
                        +88017XXXXXXXX{" "}
                      </p>
                      <div className="mt-5">
                        {/* <i className="fa-sharp fa-solid fa-qrcode text-[#6A7469]"></i> */}
                        {planName === "Basic" ? (
                          <a
                            href="https://shop.bkash.com/solutya-pvt-ltdrm54988/pay/bdt1499/ZuByoF"
                            className="bg-[#b22ff0] text-white font-bold w-full py-2 px-4 rounded"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Pay Here First : {planName}
                          </a>
                        ) : planName === "Standard" ? (
                          <a
                            href="https://shop.bkash.com/solutya-pvt-ltdrm54988/pay/bdt2999/qV0cZ6"
                            className="bg-[#b22ff0] text-white font-bold w-full py-2 px-4 rounded"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Pay Here First : {planName}
                          </a>
                        ) : planName === "Professional" ? (
                          <a
                            href="https://shop.bkash.com/solutya-pvt-ltdrm54988/pay/bdt5999/FLanSH"
                            className="bg-[#b22ff0] text-white font-bold w-full py-2 px-4 rounded"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Pay Here First : {planName}
                          </a>
                        ) : (
                          <a
                            href="https://shop.bkash.com/solutya-pvt-ltdrm54988/paymentlink"
                            className="bg-[#b22ff0] text-white font-bold w-full py-2 px-4 rounded"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Pay Here First : {planName}
                          </a>
                        )}
                      </div>
                      <div className="mt-10 space-y-3 text-black">
                        <div className="flex justify-between ">
                          <p className="mt-3">bKash Number :</p>{" "}
                          <input
                            name="bkashNo"
                            type="text"
                            required
                            placeholder="017XXXXXXXXX"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                        <div className="flex justify-between">
                          <p className="mt-3">bKash Transaction ID :</p>
                          <input
                            name="bKshTransactionId"
                            type="text"
                            required
                            placeholder="A897NV7GHJH76"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : null}

                  <div className="flex items-center mb-4">
                    <input
                      onClick={() => {
                        setBank(false);
                        setNagadSign(true);
                        setbkashSign(false);
                      }}
                      id="country-option-2"
                      type="radio"
                      name="countries"
                      value="Germany"
                      className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300  focus:outline-none"
                    />
                    <label
                      for="country-option-2"
                      className="block ml-2 text-sm font-medium text-gray-900 "
                    >
                      Nagad
                    </label>
                  </div>

                  {nagadSign ? (
                    <div className="bg-[#FFFFFF] p-3 my-5 text-[#777877] py-5">
                      <p className="text-[15px]">
                        Please complete your Nagad Payment at first, then fill
                        up the form below. Nagad Personal Number{" "}
                        <span className="text-black font-semibold">
                          +8801911664965
                        </span>
                      </p>
                      <div className="mt-10 space-y-3">
                        <div className="flex justify-between">
                          <p className="mt-3">Nagad Number :</p>{" "}
                          <input
                            name="nagadNo"
                            type="text"
                            required
                            placeholder="019XXXXXXXX"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                        <div className="flex justify-between">
                          <p className="mt-3">Nagad Transaction ID :</p>
                          <input
                            name="nagadTransactionId"
                            type="text"
                            required
                            placeholder="A897NV7GHJH76"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : null}

                  <div className="flex items-center mb-4">
                    <input
                      onClick={() => {
                        setBank(true);
                        setNagadSign(false);
                        setbkashSign(false);
                      }}
                      id="country-option-2"
                      type="radio"
                      name="countries"
                      value="Germany"
                      className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300  focus:outline-none"
                    />
                    <label
                      for="country-option-2"
                      className="block ml-2 text-sm font-medium text-gray-900 "
                    >
                      Bank
                    </label>
                  </div>
                  {bank ? (
                    <div className="bg-[#FFFFFF] p-3 my-5 text-[#777877] py-5">
                      <p className="text-[15px]">
                        Please choose your desired bank and fill the form below.
                      </p>
                      {/* Bank Options Starts*/}
                      {/* <div className="space-y-3 pt-4">
                        <details
                          className="group [&_summary::-webkit-details-marker]:hidden"
                          open
                        >
                          <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-[#b22ff0] p-4  text-white py-2 ">
                            <h2 className="font-medium">City Bank</h2>
                            <FaArrowDown/>
                       
                          </summary>

                          <p className="mt-4 px-4 leading-relaxed font-normal text-black">
                            Bank Name :{" "}
                            <span className="font-semibold">City Bank PLC</span>{" "}
                            <br />
                            Account No :{" "}
                            <span className="font-semibold">
                              1263649474001
                            </span>{" "}
                            <br />
                            Branch Name :{" "}
                            <span className="font-semibold">Uttara</span> <br />
                          </p>
                        </details>

                        <details className="group [&_summary::-webkit-details-marker]:hidden">
                          <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-[#b22ff0] p-4  text-white py-2">
                            <h2 className="font-medium">Dutch Bangla Bank</h2>
                            <FaArrowDown/>
                          </summary>

                          <p className="mt-4 px-4 leading-relaxed text-gray-700">
                            Bank Name :{" "}
                            <span className="font-semibold">
                              Dutch Bangla Bank
                            </span>
                            <br />
                            Account No :{" "}
                            <span className="font-semibold">
                              1991100032210
                            </span>{" "}
                            <br />
                            Branch Name :{" "}
                            <span className="font-semibold">
                              Sonargaon Janopod, Uttara, Dhaka{" "}
                            </span>
                            <br />
                          </p>
                        </details>
                      </div> */}
                      <div className="">
                        <div className="flex flex-col mx-6 space-y-2">
                          <Fragment>
                            <Accordion
                              className="faqDiv h-fit my-3 rounded-md"
                              open={open === 1}
                            >
                              <AccordionHeader
                                onClick={() => handleOpen(1)}
                                className="faqHead  text-left text-base h-14 rounded-lg bg-[#b22ff0] text-white"
                              >
                                City Bank
                              </AccordionHeader>
                              <AccordionBody  className="faqPanel text-base">
                                <p className="mt-4 px-4 leading-relaxed font-normal text-black">
                                  Bank Name :{" "}
                                  <span className="font-semibold">
                                    City Bank PLC
                                  </span>{" "}
                                  <br />
                                  Account No :{" "}
                                  <span className="font-semibold">
                                    1263649474001
                                  </span>{" "}
                                  <br />
                                  Branch Name :{" "}
                                  <span className="font-semibold">
                                    Uttara
                                  </span>{" "}
                                  <br />
                                </p>
                              </AccordionBody>
                            </Accordion>
                          </Fragment>
                          <Fragment>
                            <Accordion
                              className="faqDiv  rounded-md"
                              open={open === 2}
                            >
                              <AccordionHeader
                                onClick={() => handleOpen(2)}
                                className="faqHead  text-left text-base h-14 rounded-lg bg-[#b22ff0] text-white"
                              >
                                Dutch Bangla Bank
                              </AccordionHeader>
                              <AccordionBody className="faqPanel text-base">
                              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                            Bank Name :{" "}
                            <span className="font-semibold">
                              Dutch Bangla Bank
                            </span>
                            <br />
                            Account No :{" "}
                            <span className="font-semibold">
                              1991100032210
                            </span>{" "}
                            <br />
                            Branch Name :{" "}
                            <span className="font-semibold">
                              Sonargaon Janopod, Uttara, Dhaka{" "}
                            </span>
                            <br />
                          </p>
                              </AccordionBody>
                            </Accordion>
                          </Fragment>
                        </div>
                      </div>
                      {/* Bank Options Ends*/}
                      <div className="mt-10 space-y-3 text-black">
                        <div className="flex justify-between">
                          <p className="mt-3">Bank Name :</p>{" "}
                          <input
                            name="bankName"
                            type="text"
                            required
                            placeholder="XXXXXXX"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                        <div className="flex justify-between">
                          <p className="mt-3">Bank Account :</p>
                          <input
                            name="bankAccount"
                            type="text"
                            required
                            placeholder="XXXXXXX"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                        <div className="flex justify-between">
                          <p className="mt-3">Transaction ID :</p>
                          <input
                            name="bankTransactionId"
                            type="text"
                            placeholder="A897NV7GHJH76"
                            className=" p-2 border focus:outline-none"
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="flex items-center mb-4">
                    <input
                      name="policy"
                      id="checkbox-1"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500  focus:ring-2"
                    />
                    <label
                      for="checkbox-1"
                      className="ml-2 text-sm font-medium text-gray-900 "
                    >
                      I would like to receive exclusive emails with discounts
                      and product information (optional).
                    </label>
                  </div>
                  <hr />

                  <p className="text-[13px] py-5">
                    Your personal data will be used to process your order,
                    support your experience throughout this website, and for
                    other purposes described in our{" "}
                    <b>
                      <a href="/policy">privacy policy</a>
                    </b>
                    .
                  </p>
                  <hr />
                  <div className="flex items-center mb-4 mt-4">
                    <input
                      name="terms"
                      id="checkbox-1"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500  focus:ring-2"
                    />
                    <label
                      for="checkbox-1"
                      className="ml-2 text-sm font-medium text-gray-900 "
                    >
                      I agree to the{" "}
                      <a
                        href="/terms"
                        className="text-blue-600 hover:underline "
                      >
                        terms and conditions
                      </a>
                      .<span className="text-[#F33112]">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="bg-[#b22ff0] text-white font-bold w-full py-2 px-4 rounded"
              >
                PAY NOW &#62;
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
