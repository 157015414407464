import React, { useEffect } from "react";
import { data } from "../../utils/demo";
import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Updated from "../landing page/updated/Updated";
import { Link } from "react-router-dom";
import Client from "./Client";
import NewClient from "./NewClient";
import { FaWhatsapp } from "react-icons/fa";

export default function Reseller() {
  const [country, setCountry] = useState("BD");
  const [currency, setCurrency] = useState("BDT");
  const [currencySign, setCurrencySign] = useState("৳");

  const [resellerAmount, setResellerAmount] = useState(14999.0);
  const [whiteYearly, setWhiteYearly] = useState(9999.0);
  const [whiteLifetime, setWhiteLifetime] = useState(44999.0);

  const handleChange = () => {
    if (country === "BD") {
      setCountry("US");
      setCurrency("United States Dollar - USD");
      setCurrencySign("$");
      setResellerAmount((resellerAmount * 0.0098).toFixed(2));
      setWhiteYearly((whiteYearly * 0.0098).toFixed(2));
      setWhiteLifetime((whiteLifetime * 0.0098).toFixed(2));
    } else if (country === "US") {
      setCountry("BD");
      setCurrency("Bangladeshi Taka - BDT");
      setCurrencySign("৳");
      setResellerAmount((resellerAmount / 0.0098).toFixed(0));
      setWhiteYearly((whiteYearly / 0.0098).toFixed(0));
      setWhiteLifetime((whiteLifetime / 0.0098).toFixed(0));
    }
  };

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <div className="reseller">
        <section className=" flex flex-col lg:flex-row px-6 py-8 md:px-[10%] md:py-12 justify-center items-center w-fit">
          <div className="space-y-7">
            <h1 className="text-4xl">
              <b>Earn Money By Reselling WappSolutya With Your Company Name</b>
            </h1>
            <p>
              Sell whitelabel WhatsApp marketing platform to your customers at
              your own set prices. Unlimited licenses included.
            </p>
            <p>
              <a href="#pricing" className="homeButton homeButton1">
                View Pricing
              </a>
            </p>
            <p className="text-sm">
              <i>Powered by Official Cloud API from WhatsApp</i>
            </p>
          </div>
          <div>
            <img
              src={data.prod2}
              className="w-72 md:max-w-96"
              alt="headerimage"
            />
          </div>
        </section>

        <section className=" flex flex-col lg:flex-row  justify-center items-center gap-6 bg-[#EFF0F7]  px-6 py-8 md:px-[10%] md:py-12">
          <div className="space-y-6 my-auto max-w-96">
            <h1 className="text-4xl font-bold">
              Selling Stuff is Easy. Become a Reseller with WappSolutya.
            </h1>
            <p>
              There are countless great reasons to resell our products. Start
              now to generate unlimited revenue.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className=" max-w-80 h-48 p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl ">
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">
                Complete Whitelabel
              </h5>
              <p className="font-normal text-gray-700 ">
                <small>
                  Get a readymade business on your name so that you can sell
                  easily.
                </small>{" "}
              </p>
            </div>
            <div className=" max-w-80 h-48 p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl ">
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">
                Great Customer Support
              </h5>
              <p className="font-normal text-gray-700 ">
                <small>
                  Our team is here to assist you with anything you need. All you
                  need to do is ask.
                </small>{" "}
              </p>
            </div>

            <div className=" max-w-80 h-48 p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl ">
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">
                Hosted Solutions
              </h5>
              <p className="font-normal text-gray-700 ">
                <small>
                  We host the WhatsApp marketing application for you so that you
                  just have to pay and get started.
                </small>{" "}
              </p>
            </div>
            <div className=" max-w-80 h-48 p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl ">
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 ">
                Your Own Prices
              </h5>
              <p className="font-normal text-gray-700 ">
                <small>
                  You set your prices. No terms and no conditions applied.
                </small>{" "}
              </p>
            </div>
          </div>
        </section>

        <section className="resell-online text-center mt-[130px] space-y-5 px-6 py-8 md:px-[10%] md:py-12">
          <h1 className="text-4xl mx-5 lg:mx-0">
            <b>Resell Online. Make Money. Repeat.</b>
          </h1>
          <p className="mx-10">
            Pay only once for getting your Whitelabel. Now you can set your own
            prices and then resell it as per your client's budget. Isn't that
            great?
          </p>
          <div className="flex flex-col lg:flex-row lg:space-x-[40px] space-y-10 lg:space-y-0 mt-[90px] justify-center items-center">
            <div>
              <div className="text-left block max-w-sm h-full p-6 space-y-5 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  Sell Easy
                </h5>
                <p className="font-normal">
                  We provide you the with the product. We provide you with easy
                  to understand tutorial and video's. We educate you so that you
                  can now easily sell to your customers and convince them to use
                  WhatsApp marketing software.
                </p>
              </div>
            </div>
            <div>
              <div className="text-left block max-w-sm h-full p-6 space-y-5 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  Manage Licenses
                </h5>
                <p className="font-normal">
                  When a client makes payment to you, we provide you with
                  necessary system so that you can generate their license and
                  set their start and end date. While you focus on establishing
                  a solid relationship with your clients, we will silently
                  supply the necessary technology and resources.
                </p>
              </div>
            </div>
            <div>
              <div className="text-left block max-w-sm h-full p-6 space-y-5 bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-2xl">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  Hosted Solutions{" "}
                </h5>
                <p className="font-normal">
                  You need not worry about the updates provided by WhatsApp or
                  managing the website. We will host the application for you and
                  prices are already included. You just have to point your
                  domain name to our IP address. We will guide you for the same.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="like-there bg-[#EFF0F7] px-6 py-8 md:px-[10%] md:py-12">
          <div className="flex flex-col lg:flex-row gap-6 py-[90px] ">
            <div className="space-y-6">
              <h1 className="text-4xl font-bold">
                It's Like We're There, But We're Not!
              </h1>
              <p>
                Online WhatsApp marketing software platform branded with your
                company name is included with the plan with hosting provided.
              </p>
              <div className="space-y-5">
                <div className="flex space-x-5 ">
                  <span className="bg-[#443a5f] rounded-[30px] p-[10px] text-white h-full">
                    <FaWhatsapp />
                  </span>
                  <div className="space-y-3">
                    <h1 className="text-2xl font-semibold">
                      Harness the Power of WhatsApp
                    </h1>
                    <div>
                      WhatsApp is the most powerful upcoming channel for
                      marketing and we provide you with a tool that you can
                      leverage to resell WhatsApp services.
                    </div>
                  </div>
                </div>
                <div className="flex space-x-5 ">
                  <i className="fa-sharp fa-solid fa-building-columns bg-[#443a5f] rounded-[30px] p-[10px] text-white h-full"></i>
                  <div className="space-y-3">
                    <h1 className="text-2xl font-semibold">
                      Create a Business Like No Other!
                    </h1>
                    <div>
                      We are the only Whitelabel service provider on top of
                      WhatsApp cloud API. There is a massive demand for WhatsApp
                      marketing and we provide you with the exact official tool.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:ml-40 ">
              <img
                src={data.like}
                className=" w-80 lg:w-96 md:max-w-80 p-6"
                alt="asd"
              />
            </div>
          </div>
        </section>

        {/* <section className="investment mt-[130px]  px-6 py-8 md:px-[10%] md:py-12">
          <div>
            <h1 className="text-4xl text-center tracking-wide mb-[90px]">
              <b>
                How much investment is needed to become a WappSolutya Reseller?
              </b>
            </h1>

            <div className="forPC flex space-x-5 mx-44 mb-16 justify-center">
              <table className="w-full rounded-2xl overflow-hidden">
                <thead className="">
                  <tr className="bg-[#150937] text-white text-center text-2xl h-20 font-semibold">
                    <th>Investment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td className="pl-3">
                      Non-refundable Whitelabel fee for WhatsApp marketing
                      platform.
                    </td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td className="pl-3">
                      License for Using our Software's & Servers
                    </td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td className="pl-3">Support</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td className="pl-3">Training</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td className="pl-3">Marketing Material</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td className="pl-3">WhatsApp Message Cost</td>
                  </tr>
                  <tr className="bg-[#150937] text-white text-center text-2xl h-12">
                    <td className="pl-3">
                      <b>Total</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="w-1/2 text-center rounded-2xl overflow-hidden">
                <thead>
                  <tr className="bg-[#150937] text-white text-2xl h-20 font-semibold">
                    <th>Frequency</th>{" "}
                  </tr>
                </thead>
                <tbody>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>Yearly / Lifetime</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>Per message</td>
                  </tr>
                  <tr className="bg-[#150937] text-white text-center h-12">
                    <th></th>
                  </tr>
                </tbody>
              </table>
              <table className="w-1/2 text-center rounded-2xl overflow-hidden">
                <thead>
                  <tr className="bg-[#150937] text-white  text-2xl h-20 font-semibold">
                    <th>Amount</th>{" "}
                  </tr>
                </thead>
                <tbody>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 499 / $ 999</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>Directly pay to WhatsApp</td>
                  </tr>
                  <tr className="bg-[#150937] text-white text-center h-12">
                    <td>
                      <b>$ 499 / $ 999</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="forPhone flex space-x-5 mx-10 my-16 text-center justify-center">
              <table className="w-full rounded-2xl overflow-hidden">
                <thead className="">
                  <tr className="bg-[#150937] text-white text-center text-2xl h-20">
                    <th>Investment</th>
                  </tr>
                  <tr className="bg-[#150937] text-white text-2xl h-20">
                    <th>Frequency</th>
                  </tr>
                  <tr className="bg-[#150937] text-white  text-2xl h-20 font-semibold">
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-[#eff0f7] h-12">
                    <td className="pl-3">
                      Non-refundable Whitelabel fee for WhatsApp marketing
                      platform.
                    </td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>Yearly / Lifetime</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>$ 499 / $ 999</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td className="pl-3">
                      License for Using our Software's & Servers
                    </td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td className="pl-3">Support</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td className="pl-3">Training</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td className="pl-3">Marketing Material</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td className="pl-3">WhatsApp Message Cost</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>Per message</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>Directly pay to WhatsApp</td>
                  </tr>
                  <tr className="bg-[#150937] text-white text-center text-2xl h-12">
                    <td className="pl-3">
                      <b>Total</b>
                    </td>
                  </tr>
                  <tr className="bg-[#150937] text-white text-center h-12">
                    <td>
                      <b>$ 499 / $ 999</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <h1 className="text-4xl text-center tracking-wide my-[90px]">
              <b>How much revenue can you earn per month?</b>
            </h1>

            <div className="forPC flex space-x-5 mx-44  mt-[90px] justify-center">
              <table className="w-2/12 text-center rounded-t-2xl overflow-hidden">
                <thead>
                  <tr className="bg-[#150937] text-white text-2xl h-20 font-semibold">
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>Yearly / Lifetime</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                </tbody>
              </table>

              <table className="w-2/12 text-center rounded-t-2xl  overflow-hidden">
                <thead>
                  <tr className="bg-[#150937] text-white  text-2xl h-20 font-semibold">
                    <th>Clients</th>{" "}
                  </tr>
                </thead>
                <tbody>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 499 / $ 999</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="[&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                </tbody>
              </table>

              <table className="w-1/4 overflow-hidden rounded-t-2xl">
                <thead>
                  <tr style={{ backgroundColor: "#150937", color: "white" }}>
                    <th colSpan="2" className="py-2.5">
                      BDT Revenue
                    </th>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "#B22FF0",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    <th className="border-r-[5px] px-2 py-2.5 font-medium">
                      Per Month Revenue
                    </th>
                    <th className="px-2 py-2.5 font-medium">Yearly Revenue</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3 ">120000</td>
                    <td className="py-3 ">BDT 490</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">240000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">360000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">480000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">600000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                </tbody>
              </table>

              <table className="w-1/4 overflow-hidden rounded-t-2xl">
                <thead>
                  <tr style={{ backgroundColor: "#150937", color: "white" }}>
                    <th colSpan="2" className="py-2.5">
                      USD Revenue
                    </th>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "#B22FF0",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    <th className="border-r-[5px] px-2 py-2.5 font-medium">
                      Per Month Revenue
                    </th>
                    <th className="px-2 py-2.5 font-medium">Yearly Revenue</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3 ">120000</td>
                    <td className="py-3 ">BDT 490</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">240000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">360000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">480000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                  <tr className="text-center [&:nth-child(odd)]:bg-[#f7f7fc] [&:nth-child(even)]:bg-[#eff0f7]">
                    <td className="border-r-[5px] py-3">600000</td>
                    <td className="py-3">$ 14.7</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="forPhone flex space-x-5 mx-10 mt-16 justify-center">
              <table className="w-full text-center rounded-t-2xl overflow-hidden">
                <thead>
                  <tr className="bg-[#150937] text-white text-2xl h-20 font-semibold">
                    <th>Year</th>
                  </tr>
                  <tr className="bg-[#150937] text-white  text-2xl h-20 font-semibold">
                    <th>Clients</th>
                  </tr>
                  <tr className="bg-[#150937] text-white  text-2xl h-20 font-semibold">
                    <th>BDT Revenue</th>
                  </tr>
                  <tr className="bg-[#b22ff0] text-white  text-sm h-20 font-semibold">
                    <th className="px-2 py-2.5 font-medium">
                      Per Month Revenue
                    </th>
                  </tr>
                  <tr className="bg-[#b22ff0] text-white  text-sm h-20 font-semibold">
                    <th className="px-2 py-2.5 font-medium">Yearly Revenue</th>
                  </tr>
                  <tr className="bg-[#150937] text-white  text-2xl h-20 font-semibold">
                    <th>USD Revenue</th>
                  </tr>
                  <tr className="bg-[#b22ff0] text-white  text-sm h-20 font-semibold">
                    <th className="px-2 py-2.5 font-medium">
                      Per Month Revenue
                    </th>
                  </tr>
                  <tr className="bg-[#b22ff0] text-white  text-sm h-20 font-semibold">
                    <th className="px-2 py-2.5 font-medium">Yearly Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>Yearly / Lifetime</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>$ 499 / $ 999</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td className="py-3 ">BDT 490</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td className="py-3 ">BDT 490</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#f7f7fc] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#f7f7fc]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>One Time</td>
                  </tr>
                  <tr className="bg-[#eff0f7] h-12">
                    <td>$ 0. Price included</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td>120000</td>
                  </tr>
                  <tr className="text-center bg-[#eff0f7]">
                    <td className="py-3 ">$ 14.7</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-center text-white bg-[#150937] w-full  py-5 px-2 m-auto justify-center rounded-b-2xl">
              <p className="">
                Note : Calculations based on assumptions that every month you
                are getting 5 new customers. Each customer is paying you Rs.
                2000 / USD 40 per month.
              </p>
            </div>
          </div>
        </section>

        <section className="step bg-[#EFF0F7] space-y-10 mt-[130px]  px-6 md:px-[10%] md:py-12">
          <h1 className="text-4xl font-bold text-center">
            4 Steps To Become A Successfull Reseller
          </h1>
          <img src={data.steps} className="mx-auto" alt="" />
        </section> */}

        <section
          id="pricing"
          className="pricing mt-[130px] px-6 py-8 md:px-[10%] md:py-12"
        >
          <div className="space-y-5 lg:space-y-5 my-10 mb-7 lg:mx-0 justify-center">
            <div className="space-y-3 lg:w-4/12 lg:mx-auto text-center px-6">
              {/* <div className='font-medium'>Country*</div> */}
              <div className="toggle-btn space-x-3 w-full">
                <span className="">Bangladesh</span>
                <label onChange={handleChange} className="switch">
                  <input type="checkbox" id="checbox" />
                  <span className="slider round"></span>
                </label>
                <span>USA</span>
              </div>
            </div>

            <div className="flex mt-[90px] space-x-3 lg:w-4/12 lg:mx-auto px-6">
              <label
                htmlFor="currency"
                className="block my-2 text-base font-medium text-gray-900 "
              >
                Currency
              </label>
              <input
                type="text"
                id="currency"
                value={currency}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col gap-8 lg:flex-row justify-center items-center h-full">
            <div className="block mx-auto max-w-80 lg:w-[35rem] py-6 px-4 md:px-10 space-y-7 text-center bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 lg:h-[840px]">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 ">
                BASIC
              </h5>
              <div className="w-3/4 mx-auto flex justify-center text-left">
                <p className="font-normal mx-auto text-black  space-y-2">
                  <p className="text-2xl">{currencySign}</p>{" "}
                  <p className="text-5xl">{resellerAmount}</p>{" "}
                  <p>Yearly / Unlimited PC</p>
                </p>
              </div>
              <Link to={`/payments/reseller-basic`}>
                <button className="homeButton homeButton1 mt-3 w-52">
                  Buy Now
                </button>
              </Link>
              <div className="space-y-1">
                <p className="text-base font-bold text-left">
                  Plan is valid for one year and for unlimited PC
                  
                </p>
               
                <p className="text-xs text-red-600 text-left">
                  * <i>Excludes Vat & Tax</i>{" "}
                </p>
              </div>
              <ul className="space-y-4 text-sm text-left">
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  WhatsApp marketing software platform
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Unlimited reselling license
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Whitelabel with your logo and company name
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Free updates, emails and chat support
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Setup within 3 days of order
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  License management
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Set your own price to resell
                </li>
              </ul>
              <div className="space-y-3">
                <Link to="/softwareDemo" className="text-blue-600">
                  <p>Start Free Demo</p>
                </Link>
                <p className="text-xs">No credit card details required</p>
              </div>
            </div>

            <div className="block mx-auto max-w-80 lg:w-[35rem] py-6 px-4 md:px-10 space-y-7 text-center bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 lg:h-[840px]">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-600 ">
                WHITE LABEL SOFTWARE - LIFETIME
              </h5>
              <div className="w-3/4 mx-auto flex justify-center text-left ">
                <p className="font-normal text-black  space-y-2">
                  <span className="text-2xl">{currencySign}</span><br />
                  <span className="text-5xl">{whiteLifetime}</span><br />
                  <span>Lifetime / Multiple PC</span>
                </p>
              </div>
              <Link to={`/payments/reseller-premium`}>
                <button className="homeButton homeButton1 mt-3 w-52">
                  Buy Now
                </button>
              </Link>
              <div className="">
                <p className="text-base text-left max-w-[340px] leading-relaxed">
                  This pricing plan is valid for a lifetime on multiple PC.{" "}
                  <b>
                    After purchase just upload your logo and name to modify the
                    software &
                  </b>{" "}
                  resell WappSolutya with your company name immediately.
                </p>
                <p className="text-xs text-red-600 text-left">
                  * <i>Excludes Vat & Tax</i>{" "}
                </p>
                {/* <Link to="/reseller"><p className='my-5 text-blue-500'>View Reseller Page</p></Link>  */}
              </div>
              <ul className="space-y-4 text-sm text-left">
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  WhatsApp marketing software platform
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Unlimited reselling license
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Whitelabel with your logo and company name
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Free updates, emails and chat support
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Setup within 3 days of order
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  License management
                </li>
                <li>
                  <i className="fa-sharp fa-solid fa-circle-check mr-2"></i>
                  Set your own price to resell
                </li>
              </ul>
              <div className="space-y-3">
                <Link to="/softwareDemo" className="text-blue-600">
                  <p>Start Free Demo</p>
                </Link>
                <p className="text-xs">No credit card details required</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <Client />
          {/* <NewClient /> */}
        </section>

        <section className="faq my-[160px]">
          <h1 className="text-4xl text-center">
            <b>FAQ's</b>
          </h1>
          <div className="faqBox pt-[90px] px-6">
            <div className="flex flex-col mx-6 lg:mx-44 space-y-6">
              <Fragment>
                <Accordion className="faqDiv my-3 rounded-md" open={open === 1}>
                  <AccordionHeader
                    onClick={() => handleOpen(1)}
                    className="faqHead  text-left text-base h-16 rounded-lg"
                  >
                    What is WhiteLabel?
                  </AccordionHeader>
                  <AccordionBody
                    
                    className="faqPanel text-base"
                  >
                    White label products means that we will develop the product
                    of WhatsApp marketing and host it on our server with your
                    name so that you can resell to your customers. You can
                    resell at you own desired price.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className="faqDiv  rounded-md" open={open === 2}>
                  <AccordionHeader
                    onClick={() => handleOpen(2)}
                    className="faqHead  text-left text-base h-16 rounded-lg"
                  >
                    Who will provide support to our customers?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-base">
                    As a Reseller, you will have to provide support to your own
                    customers. We can only train you and educate you on the
                    product.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>
          </div>
        </section>

        <Updated />
      </div>
    </>
  );
}
