import React from 'react';
import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import 'react-accessible-accordion/dist/fancy-example.css';

export default function FAQ() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <>
      <section className='faq mt-[65px] lg:mt-[130px] pt-[35px] lg:pt-[90px] pb-[45px] bg-[#f7f7fc]'>
        <h1 className='text-4xl text-center'><b>FAQ's</b></h1>
        
        <div className='faqBox py-[45px] lg:py-[90px] mx-10 lg:mx-0'>

          <div className='flex'>
            <div className='flex flex-col w-full lg:w-2/4 lg:ml-[11rem] mr-5'>
              <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 1}>
                  <AccordionHeader onClick={() => handleOpen(1)} className="faqHead text-left text-base">
                  Do I get a receipt after purchase? Do I get a Bin No. invoice?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "100px"}}>
                  We provide receipt to each and every user for purchase of WappSolutya software. Once you make the payment, you just need to enter your company details and go to the download section to view or save your receipt.
                  </AccordionBody>
                </Accordion>
              </Fragment> 
              <Fragment>
                <Accordion className='faqDiv my-3 rounded-md' open={open === 2}>
                  <AccordionHeader onClick={() => handleOpen(2)} className="faqHead  text-left text-base">
                  How many WhatsApp messages can I send per day? Will my number be blocked if I send too many WhatsApp messages using the WhatsApp business API?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "370px"}}>
                  The number of WhatsApp messages you can send depends on users based on a tier system. Every new account starts at tier 1 after passing the business verification process. You are automatically upgraded to the next tier based on the volume and quality of the messages you send. WhatsApp will not ban your number. Conversely, users can be downgraded if one or more of your messages are reported or blocked.
                  Tier 1 allows you to send messages to 1,000 unique customers in a rolling 24-hour period.
                  Tier 2 allows you to send messages to 10,000 unique customers in a rolling 24-hour period.
                  Tier 3 allows you to send messages to 100,000 unique customers in a rolling 24-hour period.
                  Tier 4: Allows you to send messages to an unlimited number of customers in a rolling 24-hour period.
                  Note: A business starts in Tier 1 when it registers its phone number with WhatsApp.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 3}>
                  <AccordionHeader onClick={() => handleOpen(3)} className="faqHead  text-left text-base">
                  What are the disadvantages of the WhatsApp marketing tool?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "270px"}}>
                  It becomes a little cumbersome. After 24 hours, the session expires, and businesses can only send "template messages" to their users. Template messages must be pre-approved by WhatsApp and onboarded with a WhatsApp business API. The number cannot be used on any other WhatsApp apps - including the WhatsApp messenger and WhatsApp business mobile application. WhatsApp business API is not free, WhatsApp's pricing model charges a small fee per message. However, this is only charged once the company has exceeded 1,000 free monthly messages.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 4}>
                  <AccordionHeader onClick={() => handleOpen(4)} className="faqHead  text-left text-base">
                  Is WhatsApp business API free to use?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "150px"}}>
                  No, WhatsApp Business API isn't free. You need to pay WhatsApp message charges for business and user initiated messages and platform charges for using a WhatsApp business API based platform like WappSolutya. We don't charge any additional cost per message.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 5}>
                  <AccordionHeader onClick={() => handleOpen(5)} className="faqHead  text-left text-base">
                  How to send bulk WhatsApp messages from PC free?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "90px"}}>
                  WappSolutya is a web-based software. You just need to login via your PC, Mobile and you will be able to send messages. 
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 6}>
                  <AccordionHeader onClick={() => handleOpen(6)} className="faqHead  text-left text-base">
                  How many WhatsApp messages can I send in a single day?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "200px"}}>
                  There is a limit to the number of messages you can send. The daily limit is based on which tier your account lies in. Most of the accounts are in.

Tier 1 which allows your company to send messages to 1K customers per day. Your account updates automatically to.

tier 2 once you start sending messages regularly and after that, you are allowed to send messages to an even larger number of users.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 7}>
                  <AccordionHeader onClick={() => handleOpen(7)} className="faqHead  text-left text-base">
                  What are the disadvantages of the WhatsApp marketing tool?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "120px"}}>
                  WhatsApp marketing is a great tool to convert users into paid customers. There are no major disadvantages except that each message is chargeable and you have to pay money directly to Facebook for each message.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 8}>
                  <AccordionHeader onClick={() => handleOpen(8)} className="faqHead  text-left text-base">
                  Is WhatsApp marketing tool free to use?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "80px"}}>
                  The tool is chargeable with one time fee. After that you will have to pay charge for each message directly to WhatsApp.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>

            <div className='flex flex-col w-full lg:w-2/4 lg:mr-[11rem] lg:ml-5'>
            <Fragment className="">
                <Accordion className='faqDiv my-3 rounded-md' open={open === 9}>
                  <AccordionHeader onClick={() => handleOpen(9)} className="faqHead  text-left text-base">
                  Is WhatsApp business API legal? Is WhatsApp business API legal in India?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "160px"}}>
                  Yes, the WhatsApp business API is legal in India, and it is used by many large companies to send messages. And it's an entirely transactional route for communication as you can share invoices, alerts, appointment confirmation or cancellation and much more.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 10}>
                  <AccordionHeader onClick={() => handleOpen(10)} className="faqHead text-left  text-base">
                  How can I send 1000 messages on WhatsApp?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "160px"}}>
                  Sending 1000's messages is very easy via WappSolutya bulk WhatsApp sender. You can simply import the numbers to the software and start sending it to them. You can even customize their first and last name with variable settings in the website admin.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 11}>
                  <AccordionHeader onClick={() => handleOpen(11)} className="faqHead text-left  text-base">
                  What type of content can be shared through Bulk WhatsApp Sender?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "230px"}}>
                  WhatsApp Bulk Sender helps send text messages along with images, videos, sound and pictures. Promoting your business is important as most other organizations depend on WhatsApp to promote their products. So it is necessary to keep pace with the times and utilize WhatsApp Bulk Sender as a marketing tool.
                  Easily share promotional plans, business addresses, and information about re-launched products. It is also possible to send business cards, real-time coupons and audio information
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 12}>
                  <AccordionHeader onClick={() => handleOpen(12)} className="faqHead text-left  text-base">
                  What are Anti-Block settings for WappSolutya?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "230px"}}>
                  WappSolutya has a list of settings that can help to send a large number of messages without getting your account blocked.
                  1: With WappSolutya, you can send multiple variants of messages in single time to customize the message and so your message will have personalization.
                  2: WappSolutya allows you to import files with almost 15 variables that can help you to even further distinguish each of your messages. For example, you can import a file with a name & number and use the name to make each message unique.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 13}>
                  <AccordionHeader onClick={() => handleOpen(13)} className="faqHead text-left  text-base">
                  How does WhatsApp price work?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "630px"}}>
                  WhatsApp, as well as WhatsApp business mobile applications, are available free of cost. However, this application doesn't help you in automating your marketing needs and here is where WappSolutya bridges the gap. If you are wondering what is WhatsApp price you have to pay, head over to this link. We have curated the best possible solution to accommodate your needs to give you the best results and much more. We at WappSolutya focus on giving our clients the best user experience they could ever ask for and hence, have successfully built a trustworthy relationship with them over time through our tools and other marketing WhatsApp channels.

Our service and quality speak for us. WappSolutya is the best WhatsApp tool and WhatsApp channel available in the market and we have proven our results in time and again. Our customers are here to stay and we hope you don't flinch an eye to onboard our services right from the bat of the field.

Why do you wish to opt for paid services when WappSolutya; WhatsApps free marketing software offers everything you need at absolutely no cost? If you are result-driven and wish to grow your business tenfold, then this is the right choice for you to make and we suggest you make it now.

Amongst the latest WhatsApp marketing software that is driving businesses towards their goals and helping them skyrocket in this pandemic time is none other than WappSolutya, WhatsApps free marketing software. Right from gaining trust to delivering results, we ace every opportunity that comes our way to make you want more.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 14}>
                  <AccordionHeader onClick={() => handleOpen(14)} className="faqHead text-left  text-base">
                  What is the limit for WhatsApp messages?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "80px"}}>
                  No limit for the message content. You can send any message in any format you wish to.
                  </AccordionBody>
                </Accordion>
              </Fragment>
              <Fragment>
                <Accordion className='faqDiv my-3  rounded-md' open={open === 15}>
                  <AccordionHeader onClick={() => handleOpen(15)} className="faqHead text-left  text-base">
                  How Free Bulk WhatsApp Sender Software Helps Small Businesses?
                  </AccordionHeader>
                  <AccordionBody className="faqPanel text-lg" style={{height: "230px"}}>
                  One of the most efficient online marketing strategies is through a well-equipped WhatsApp Marketing software. Open source and free bulk WhatsApp sender solutions are available in abundance today, making it easy for small businesses to tap into the WhatsApp consumer base while also ensuring compliance. Due to low to no charges for these bulk WhatsApp sender tools, small businesses just beginning their entrepreneurial journey can increase their visibility.
                  </AccordionBody>
                </Accordion>
              </Fragment>
            </div>
          </div>
          
        </div>

      </section>
    </>
  )
}
