import React from 'react'

export default function AdminProfile() {
  return (
    <>
     <div>
     <div className='space-y-6 mt-8 mx-4'>
     <h1 className='text-2xl font-semibold'>Profile</h1>  
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Admin Name: </b> Arif Billah Aman</p>         
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Admin Address: </b> Imagine Osman Center, Holding No. 34 (Islami Bank Building, Road Sonargaon Janapath, Dhaka 1230</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Company City: </b> Dhaka</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Company Country: </b> Bangladesh</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Tax No: </b></p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Currency: </b> BDT</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>State Name: </b> BDT</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Country Code: </b> +880</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Mobile No: </b> +880</p>          
     <p className="font-normal text-gray-700 dark:text-gray-400 tracking-wide"><b>Email: </b> +880</p>
     </div>
     </div> 
    </>
  )
}
