import React from 'react'
import { data } from '../../../utils/demo'

export default function Updated() {
  return (
    <>
     <section className='updated-date mt-[130px] flex hidden'>
            <img src={data.logo} alt="" className=''/>
            <b className='my-auto'>By WappSolutya | Last Updated: November 19. 2022</b>
        </section>  
    </>
  )
}
