import React from 'react'

export default function Profile() {
  return (
    <>
     <div className='profile space-y-6 mt-8 mx-4'>
        <h1 className='text-2xl font-semibold'>Company Information</h1>

        <div className='grid grid-cols-1 lg:grid-cols-2'>
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                    First Name :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                    Last Name :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                    Company Name* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                    Company Address* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Company City* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Company Country* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                BIN No :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Currency* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                State Name* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Country code* :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Mobile No :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Email :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div> 
        <div className="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block text-gray-700 text-base font-semibold mb-2" htmlFor="grid-first-name">
                Login Password :
                </label>
                <input className="appearance-none block w-[200%] bg-gray-200 text-gray-700 border focus:border-blue-500 rounded-[15px] py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder=""/>
        </div>
        <div className="w-1/2 md:w-1/2 px-3 my-7 mx-auto md:mb-0">
                <button className='homeButton homeButton1'>Submit</button>
        </div>

        </div>
        
     </div> 
    </>
  )
}
