import logo from "../images/logo.svg";
import whiteLogo from "../images/white_logo.png";
// import offer from "../images/offer.jpg";
import whatBackground from "../images/what_background.png"
import canvas from "../images/giffycanvas.gif";
import complete from "../images/complete_text.png";
import product1 from "../images/product1.jpg";
import product2 from "../images/product2.png";
import features from "../images/features.png";
import overEmail from "../images/over_email.png";
import send from "../images/send.jpg";
import freeMessage from "../images/freemessages.png";
import stagoenix from "../images/stagoenix.png";
import digital from "../images/digital_walrus.png";
import SD from "../images/SDmedia.png";
import fox from "../images/fox.png";
import like from "../images/like.png";
import steps from "../images/steps.png";
import popular from "../images/popular.png";
import person1 from "../images/Person/person-1.jfif";
import person2 from "../images/Person/person-2.jpg";
import person3 from "../images/Person/person-3.jpg";
import person4 from "../images/Person/person-4.png";
import person5 from "../images/Person/person-5.jfif";
import person6 from "../images/Person/person-6.jpg"
import check from "../images/check.png";
import altImg from "../images/alt_pic.png";
import templete1 from "../images/tutorial-thumbnail-1.png";
import templete2 from "../images/tutorial-thumbnail-2.png";
import tutorial1 from "../video/tutorial1.mp4";
import bkash from "../images/bkash.png";
import nagad from "../images/nagad.png";
import bkashQr from "../images/bkashQr.jpg";
import nagadQr from "../images/nagadQr.png"

export const data = {
    logo: logo,
    whiteLogo: whiteLogo,
    // offer: offer,
    whatBackground: whatBackground,
    canvas: canvas,
    complete: complete,
    prod1: product1,
    prod2: product2,
    features: features,
    over: overEmail,
    send: send,
    message: freeMessage,
    stagoenix: stagoenix,
    digital: digital,
    SD: SD,
    fox: fox,
    like: like,
    steps: steps,
    popular: popular,
    person1: person1,
    person2: person2,
    person3: person3,
    person4 : person4,
    person5 : person5,
    person6 : person6,
    check: check,
    altImg : altImg,
    templete1 : templete1,
    templete2 : templete2,
    tutorial1 : tutorial1,
    bkash : bkash,
    nagad : nagad,
    bkashQr : bkashQr,
    nagadQr : nagadQr,

}