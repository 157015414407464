import { Button, Label, TextInput } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../../firebase.init";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";

export default function Register() {
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const userName = e.target.userName.value;
    const phone = e.target.phone.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const rePassword = e.target.rePassword.value;
    const role = "user";
    if (!name || !userName || !phone || !email)
      return alert("Please fill all the fields");
    if (password.toString() !== rePassword.toString())
      return alert("Password does not match");
    console.log(name, userName, phone, email, password);
    var userDetails = {
      name,
      username: userName,
      password,
      email,
      number: phone,
      role,
    };
    console.log(userDetails);

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDetails),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });


    createUserWithEmailAndPassword(email, password);
      navigate('/userDashboard')
    // console.log(e.target.name.value, "submit");
  };
  // useEffect(() => {
  //   console.log(user, "user");
  // }, [user]);

  // console.log(error, "error");
  return (
    <div className="h-screen flex justify-center items-center mt-10 md:mt-20">
      <form onSubmit={handleSubmit} className="w-2/5 mt-[-100px]" action="">
        <p className="flex justify-center font-bold text-2xl">
          Register Our Platform
        </p>
        <div className="space-y-4 px-6 pt-4 pb-0 sm:pb-6 lg:px-8 xl:pb-8">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value="Name" />
            </div>
            <TextInput
              name="name"
              id="name"
              placeholder="Enter your name"
              required={true}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="userName" value="User Name" />
            </div>
            <TextInput
              name="userName"
              id="userName"
              placeholder="Enter Username"
              type="text"
              required={true}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="phone" value="Phone" />
            </div>
            <TextInput
              name="phone"
              id="phone"
              placeholder="Enter your phone"
              type="text"
              required={true}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="email" value="Email" />
            </div>
            <TextInput
              name="email"
              id="email"
              placeholder="Enter your email"
              type="email"
              required={true}
            />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="password" value="Password" />
            </div>
            <div className="relative">
              <TextInput
                name="password"
                id="password"
                placeholder="Enter your password"
                type={`${showPassword ? "text" : "password"}`}
                required={true}
              />
              <p
                className="absolute right-3 top-2 z-10 opacity-50 "
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </p>
            </div>
          </div>

          <div className="">
            <div className="mb-2 block ">
              <Label htmlFor="rePassword" value="Repeat Password" />
            </div>
            <div className="relative">
              <TextInput
                name="rePassword"
                id="rePassword"
                placeholder="Enter your password again"
                type={`${showRePassword ? "text" : "password"}`}
                required={true}
              />
              <p
                className="absolute right-3 top-2 z-10 opacity-50 "
                onClick={() => setShowRePassword(!showRePassword)}
              >
                {showRePassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
              </p>
            </div>
          </div>
          <div className="mt-3">
            <Button type="submit" className="w-1/2 m-auto bg-[#c758fa]">
              Create account
            </Button>
          </div>

          <div className="text-center">
            <Link to="/login" className="text-[#c758fa]">
              <button className="text-sm font-semibold hover:border-b-[1px] hover:border-[#c758fa]">
                Go to Login Page
              </button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}
