import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
// import IntlTelInput from 'react-intl-tel-input'
import CountryCode from "../../../components/CountryCode";
import auth from "../../../firebase.init";

export default function Demo() {
  const [user] = useAuthState(auth);

  const demoHandler = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phoneNo = e.target.phoneNo.value;
    const companyName = e.target.companyName.value;
    const date = e.target.date.value;
    const time = e.target.time.value;

    const finalData = {
      name,
      email,
      phoneNo,
      companyName,
      date,
      time,
      userEmail: user.email,
    };

    fetch("https://busy-cyan-fox-suit.cyclic.app/api/v1/demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      });
    // console.log(orderDetails);
  };

  return (
    <>
      <section
        className="demo pt-[65px] lg:pt-[130px] px-[2rem] lg:px-[11rem]"
        id="demo"
      >
        <h1 className="text-4xl text-center pb-[45px] lg:pb-[90px]">
          <b>Demo Sign Up</b>
        </h1>
        <form onSubmit={demoHandler}>
          <div>
            <div className="demo-body flex">
              <div className="demo-body-input space-y-11 pr-5">
                <div>
                  <label htmlFor="name" className="block mb-2 font-medium">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phoneNo" className="block mb-2 font-medium">
                    Phone No <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="phoneNo"
                    id="phoneNo"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Your number"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="date" className="block mb-2 font-medium ">
                    Date <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {/* <IntlTelInput placeholder=''/> */}
                </div>
              </div>

              <div className="demo-body-input space-y-11 md:pl-5">
                <div>
                  <label htmlFor="email" className="block mb-2 font-medium">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="companyName"
                    className="block mb-2 font-medium"
                  >
                    Company Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Company Name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="time" className="block mb-2 font-medium">
                    Time <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="time"
                    id="time"
                    name="time"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Company Name"
                  />
                </div>
              </div>
            </div>
            <button className="homeButton homeButton1 block m-auto mt-6">
              Start Demo
            </button>
          </div>
        </form>
        <p className="mt-[1rem] text-center">
          <small>No credit card details required.</small>
        </p>
      </section>
    </>
  );
}
